import React, { useState } from "react";
import Select from 'react-select';

const QuestionSelection = ({ surveyModel }) => {

    // Setting the current "question" displayed (is technically the page)
    const onPageSelection = selected => {
        surveyModel.currentPageNo = selected.value - 1;
    };

    const getQuestions = () => {
        let tempArray = [];
        let index = 0;

        surveyModel.pages.forEach((page, i) => {
            // skip first element
            if (i > 0 && page.propertyHash.elements[0].visible) {
                index++;
                let temp = {
                    value: null,
                    label: null
                };

                temp.label = index + ". " + page.propertyHash.elements[0].title;
                temp.value = index;

                tempArray.push(temp);
            }
        });

        return tempArray;
    };

    const elements = getQuestions();

    const customStyle = {
        option: (provided, state) => ({
            ...provided,
            color: 'white',
            borderBottom: '1px solid #3f3d3d',
            padding: 15,
            backgroundColor: '#4c4c4c',

            '&:hover': {
                backgroundColor: '#6b6b6b'
            }
        }),
        control: base => ({
            ...base,
            color: 'white',
            backgroundColor: '#4c4c4c',
            boxShadow: "none",

            '&:hover': {
                borderColor: '#A9F7E3'
            },

            '&:focus': {
                outlineColor: '#A9F7E3'
            }
        }),
        menu: base => ({
            ...base,
            backgroundColor: '#4c4c4c',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    };

    let placeHolderText;
    if (sessionStorage.getItem("language") === "de") {
        placeHolderText = "Zu Frage springen..."
    } else {
        placeHolderText = "Jump to Question..."
    }

    return (
        <Select
            styles={customStyle}
            className={"q-selector col-12"}
            options={elements}
            onChange={onPageSelection}
            placeholder={placeHolderText}
            value={null}
        />
    );
};

export default QuestionSelection;