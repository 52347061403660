import React, {forwardRef, useImperativeHandle} from 'react';
import {v4 as uuidv4} from "uuid";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import RadioGroup from "./QuestionTypes/RadioGroup";
import Checkbox from "./QuestionTypes/Checkbox";
import Boolean from "./QuestionTypes/Boolean";

const DragArea = forwardRef((props, ref) => {
    const grid = 12;
    // const [questions, setQuestions] = useState([]);

    // reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: `0 0 ${grid}px 0`,
        borderRadius: 6,
        background: "#4c4c4c",


        // apply styles
        ...draggableStyle
    });

    const getListStyle = () => ({
        boxShadow: "inset 0 0 5px #242222",
        borderRadius: 5,
        background: "#3f3d3d",
        padding: grid,
        width: '100%'
    });

    useImperativeHandle(ref, () => ({
        deleteQuestion(index) {
            let tempQuestions = [...props.questions];
            tempQuestions.splice(index, 1);
            props.setQuestions(tempQuestions);
        },
        getQuestionsJSON() {
            return props.questions;
        },
        addQuestion(type) {
            let tempQuestions = [...props.questions];
            const id = uuidv4();
            switch (type) {
                case "radiogroup":
                    tempQuestions.push({
                        "type": "radiogroup",
                        "name": id,
                        "visible": true,
                        "isExam": false,
                        "title": {"default": "", "en": ""},
                        "description": {"default": "", "en": ""},
                        "choices": [],
                        "choicesOrder": "random"
                    });
                    break;
                case "checkbox":
                    tempQuestions.push({
                        "type": "checkbox",
                        "name": id,
                        "visible": true,
                        "isExam": false,
                        "title": {"default": "", "en": ""},
                        "description": {"default": "", "en": ""},
                        "choices": [],
                        "choicesOrder": "random"
                    });
                    break;
                case "boolean":
                    tempQuestions.push({
                        "type": "boolean",
                        "name": id,
                        "visible": true,
                        "isExam": false,
                        "title": {"default": "", "en": ""},
                        "description": {"default": "", "en": ""},
                        "labelTrue": {
                            "de": "Wahr",
                            "en": "True"
                        },
                        "labelFalse": {
                            "de": "Falsch",
                            "en": "False"
                        }
                    });
                    break;
                default:
                    break;
            }

            props.setQuestions(tempQuestions);

            // Scroll to bottom to see new element
            window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
        }
    }));

    const onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        const newItems = reorder(
            props.questions,
            result.source.index,
            result.destination.index
        );

        props.setQuestions(newItems);
    };

    const updateQuestion = (item, index) => {
        let tempQuestions = [...props.questions];
        switch (item.type) {
            case "radiogroup":
                tempQuestions[index - 1]["visible"] = item.visible;
                tempQuestions[index - 1]["isExam"] = item.isExam;
                tempQuestions[index - 1]["title"] = item.title;
                tempQuestions[index - 1]["description"] = item.description;
                tempQuestions[index - 1]["correctAnswer"] = item.correctAnswer;
                tempQuestions[index - 1]["choices"] = item.choices;
                break;
            case "checkbox":
                tempQuestions[index - 1]["visible"] = item.visible;
                tempQuestions[index - 1]["isExam"] = item.isExam;
                tempQuestions[index - 1]["title"] = item.title;
                tempQuestions[index - 1]["description"] = item.description;
                tempQuestions[index - 1]["correctAnswer"] = item.correctAnswer;
                tempQuestions[index - 1]["choices"] = item.choices;
                break;
            case "boolean":
                tempQuestions[index - 1]["visible"] = item.visible;
                tempQuestions[index - 1]["isExam"] = item.isExam;
                tempQuestions[index - 1]["title"] = item.title;
                tempQuestions[index - 1]["description"] = item.description;
                tempQuestions[index - 1]["correctAnswer"] = item.correctAnswer;
                break;
            default:
                break;
        }
        props.setQuestions(tempQuestions);
    };

    const getQuestionComponent = (item, index) => {
        switch (item.type) {
            case "radiogroup":
                return <RadioGroup item={item} index={index + 1} delQuestion={props.delQuestion}
                                   update={updateQuestion}/>;
            case "checkbox":
                return <Checkbox item={item} index={index + 1} delQuestion={props.delQuestion}
                                 update={updateQuestion}/>;
            case "boolean":
                return <Boolean item={item} index={index + 1} delQuestion={props.delQuestion} update={updateQuestion}/>;
            default:
                break;
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {props.questions.map((item, index) => (
                            <Draggable
                                key={item.name}
                                draggableId={item.name}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        id={item.name}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {getQuestionComponent(item, index)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default DragArea;
