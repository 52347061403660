import React, { useState } from "react";
import Select from "react-select";

const LanguageSelection = ({ langSetter }) => {
  const languages = [
    { value: 'de', label: '🇩🇪' },
    { value: 'en', label: '🇬🇧' }
  ];

  const [selection, setSelection] = useState(languages[0]);

  // Setting the current "question" displayed (is technically the page)
  const onLangSelection = selected => {
    setSelection(selected);
    langSetter(selected.value);
  };

  const customStyle = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      borderBottom: "1px solid #3f3d3d",
      padding: 15,
      backgroundColor: "#4c4c4c",
      fontSize: 20,

      "&:hover": {
        backgroundColor: "#6b6b6b",
      },
    }),
    control: base => ({
      ...base,
      color: "white",
      fontSize: 20,
      backgroundColor: "#4c4c4c",
      boxShadow: "none",
      height: 50,
      minHeight: 50,

      "&:hover": {
        borderColor: "#A9F7E3",
      },

      "&:focus": {
        outlineColor: "#A9F7E3",
      },
    }),
    menu: base => ({
      ...base,
      backgroundColor: "#4c4c4c",
    }),
    valueContainer: base => ({
      ...base,
      height: 50,
      position: 0,
    }),
    input: base => ({
      ...base,
      height: 50,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "white";

      return { ...provided, opacity, color, transition };
    },
  };

  return (
    <Select
      styles={customStyle}
      className="col-4 lang-selector"
      options={languages}
      onChange={onLangSelection}
      placeholder="Sprache..."
      value={selection}
      isOptionDisabled={option => option.isDisabled}
    />
  );
};

export default LanguageSelection;
