import axios from "axios";
import cogoToast from "cogo-toast";

// const API_BASE_URL = 'https://t2m-schulung-backend.germanywestcentral.azurecontainer.io/';
// const API_BASE_URL = 'http://localhost:5001/'; // local
const API_BASE_URL = "https://schulungen-backend-t2m.frobese.dev/" // dev Server

export const getLabels = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training/names`)
      .then(res => {
        resolve(res.data)
      })
      .catch((err) => {
        cogoToast.error("Training names could not be loaded.")
        reject(err)
      })
  })
}

export const hasSession = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/login/verify`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(res => {
        resolve(res.status)
      })
      .catch((err) => {
        reject(err.status)
      })
  })
}

export const changeLabel = (type, newLabel) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}api/training/names`, { type, newLabel }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(res => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * fetching Training JSON from API
 *
 * @return {Promise} resolves survey json
 */
export const getTraining = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training?type=${type}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error("Das Training konnte nicht geladen werden.");
        console.log(err);
        reject(err);
      });
  });
};

export const checkPrivateKey = (privateKey) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_BASE_URL}api/exam/keys`,
        { key: privateKey },
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error("Das Training konnte nicht geladen werden.");
        console.log(err);
        reject(err);
      });
  });
};

/**
 * updates existing training
 *
 * @return {Promise} resolves survey json
 */
export const updateTraining = (type, survey) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}api/training?type=${type}`, survey, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * fetching previous results from API
 * @return {Promise} resolves json with all previous trainings
 */
export const getPreviousTrainings = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training/keys`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error(
          "Es konnten keine bisherigen Trainings geladen werden!"
        );
        console.log(err);
        reject(err);
      });
  });
};

export const getPreviousExams = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/keys`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error(
          "Es konnten keine bisherigen Trainings geladen werden!"
        );
        console.log(err);
        reject(err);
      });
  });
};

export const getExamData = (description) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/keys?key=${description}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error(
          "Es konnten keine bisherigen Trainings geladen werden!"
        );
        console.log(err);
        reject(err);
      });
  });
};

export const getExamDataByKey = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/keys/valid?key=${key}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error(
          "Es konnten keine bisherigen Trainings geladen werden!"
        );
        console.log(err);
        reject(err);
      });
  });
};

/**
 * fetching previous results from API
 * @return {Promise} resolves json with all previous trainings
 */
export const getResultsForKey = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training/results?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
  });
};

export const getResultsForExam = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/results/?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.data === "No results for this training yet") {
          reject("no_results");
        } else {
          cogoToast.error("Es konnten keine Ergebnisse geladen werden!");
          reject(err);
        }
      });
  });
};

// deletes an exam object
export const deleteExamObject = id => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}api/exam/keys/delete?key=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * fetching previous results from API
 * @return {Promise} resolves json with all previous trainings
 */
export const getAverageResults = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training/results/average?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.data === "No results for this training yet") {
          reject("no_results");
        } else {
          reject(err);
        }
      });
  });
};


export const getAverageExamResults = key => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/results/average?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getExamResultData = (key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/results/data?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.data === "No results for this training yet") {
          reject("no_results");
        } else {
          reject(err);
        }
      });
  });
};

export const getExamResults = key => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/exam/results?key=${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.data === "No results for this training yet") {
          reject("no_results");
        } else {
          reject(err);
        }
      });
  });
};



/**
 * uploading results for given key
 * @return {Promise} resolves json with all previous trainings
 */
export const sendResult = (result) => {
  axios
    .post(`${API_BASE_URL}api/training/results`, result)
    .then((res) => { })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * upload key
 * @return {Promise} resolves json with all previous trainings
 */
export const uploadNewKey = (key, date, type) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${API_BASE_URL}api/training/keys`,
        { key: key, date: date, training: type },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const uploadNewExam = (
  description,
  candidates,
  keys,
  questionNumber,
  examDuration,
  validity,
  type,
  hasMultipleTypes,
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${API_BASE_URL}api/exam/keys`,
        {
          description: description,
          candidates: candidates,
          keys: keys,
          questionNumber: questionNumber,
          examDuration: examDuration,
          validity: validity,
          training: type,
          hasMultipleTypes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};


export const uploadUsedKey = (usedKey) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_BASE_URL}api/exam/keys/used?key=${usedKey}`,
        { used: usedKey },
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};



export const clearResults = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}api/training/results?key=${type}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const getTrainingAvailable = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}api/training/available`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        cogoToast.error("Das Training konnte nicht geladen werden.");
        console.log(err);
        reject(err);
      });
  });
};

export const sendTrainingAvailable = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}api/training/available`, type, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const deleteTrainingAvailable = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}api/training/available`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        data: type,
      })

      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const createExam = (
  basicQuestions,
  eventQuestions,
  rollsQuestions,
  poevmQuestions,
  okrBasicQuestions,
  okrPraxisQuestions,
  okrManagementQuestions,
  agile_coaching_leadership_Questions,
  agile_leadership_1_Questions,
  agile_leadership_2_Questions,
  agile_leadership_3_Questions,
  kanban_basics_Questions,
  kanban_ibqmi_Questions,
  agile_exam_Questions
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_BASE_URL}api/exam?basics=${basicQuestions}&events=${eventQuestions}
        &rolls=${rollsQuestions}&poevm=${poevmQuestions}&okr_basics=${okrBasicQuestions}
        &okr_praxis=${okrPraxisQuestions}&okr_management=${okrManagementQuestions}
        &agile_coaching_leadership=${agile_coaching_leadership_Questions}
        &agile_leadership_1=${agile_leadership_1_Questions}
        &agile_leadership_2=${agile_leadership_2_Questions}
        &agile_leadership_3=${agile_leadership_3_Questions}
        &kanban_basics=${kanban_basics_Questions}
        &kanban_ibqmi=${kanban_ibqmi_Questions}
        &agile_exam=${agile_exam_Questions}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response.data === "Not enough questions for this exam") {
          reject("no_results");
        } else {
          reject(err);
        }
      });
  });
};

/**
 * login route
 * @return {Promise}
 */
export const login = (username, password) => {
  const instance = axios.create({
    withCredentials: true,
    auth: {
      username: username,
      password: password,
    },
  });

  return new Promise((resolve, reject) => {
    instance
      .post(`${API_BASE_URL}api/login`)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("username", username);
          localStorage.setItem("access_token", response.data.token);
          resolve();
        } else if (response.status === 403) {
          reject("false_credentials");
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("access_token");
          window.location.reload();
          cogoToast.error("Falsche Nutzerdaten");
          reject("general_error");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
