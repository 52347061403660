import React, { useEffect, useState, useContext } from "react";
import { TrainingsContext } from '../../../context'
import { useHistory } from 'react-router-dom'
import Legend from "./Legend";
import ResultsOverview from "./ResultsOverview";
import Analytics from "./Analytics";
import ScrollUpComponent from "../../Misc/ScrollUpButton";
import ScrollUpButton from "react-scroll-up-button";
import Title from "../../Misc/Title";
import { clearResults } from "../../../utils/api";
import notFound from "../../../images/empty-state.png";
import { getTitleFromType } from "../../../utils/training-utils";


const Results = (...props) => {
    const mainColor = 'rgba(97, 189, 170, 0.5)';
    const secondaryColor = 'rgba(169, 247, 227, 0.75)';
    const [error, setError] = useState(null);
    const { key, results, averageResults, origin } = props[0].location.state

    const { data: { trainingLabels } } = useContext(TrainingsContext)
    const questionpoolTitle = getTitleFromType(trainingLabels, key)


    const history = useHistory()

    useEffect(() => {
        if (results.length == 0) {
            setError('No results were found')
        }
    }, [results.length])

    const clearRes = () => {
        const r = window.confirm("Bist du sicher das du die Ergebnisse löschen möchtest?");
        if (r === true) {
            clearResults(key).then(() => {
                history.goBack()
            });
        }
    };

    const errorComponent = (error !== null) ? (
        <div className={"col-12 text-center"}>
            <img className={"img-fluid"} src={notFound} alt={"not-found"} />
            <br />
            <h4>Es sind noch keine Ergebnisse vorhanden.</h4>
        </div>
    ) : <div>
        <div className={"row"}>
            <div className={"col-12 col-sm-12 col-md-4"} />
            <Legend
                mainColor={mainColor}
                secondaryColor={secondaryColor}
            />

            {origin == 'training' ? <div className={"col-12 col-sm-12 col-md-4 del-btn"}>
                <button className={"btn btn-transparent"} onClick={clearRes}>Ergebnisse löschen</button>
            </div> : null}

        </div>
        <ResultsOverview
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            averageResults={averageResults}
            type={key}
        />
        <br />
        <Analytics
            results={results}
            mainColor={mainColor}
            secondaryColor={secondaryColor} />
    </div>;

    return (
        <div className={"charts"}>
            <Title title={`Ergebnisse ${questionpoolTitle}`} />
            <ScrollUpButton
                ContainerClassName="scroll-up-container"
                TransitionClassName="scroll-up-transition"
            >
                <ScrollUpComponent />
            </ScrollUpButton>
            {errorComponent}
        </div>
    );
};

export default Results;
