import React, { useState, useEffect } from "react";
import * as Survey from "survey-react";
import TrainingComplete from "./../Training/TrainingComplete"
import { generateQuestionResult } from "../../utils/result-utils";
import Title from "../Misc/Title";
import ExamQuestionSelection from "./ExamQuestionSelection";
import { loadState, setTrainingTranslations } from "../../utils/training-utils";
import { sendResult, getExamDataByKey, uploadUsedKey } from "../../utils/api";
import { setQuestionVisibility } from "../../utils/exam-utils";
import ReactLoading from "react-loading";
import cogoToast from "cogo-toast";

// TODO update survey-description based on language
const Exam = (...props) => {
  const [exam, setExam] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false); // is user done?
  const [hasBegun, setHasBegun] = useState(false);
  const [number, setNumber] = useState(0);

  const language = sessionStorage.getItem("language")
  const key = sessionStorage.getItem("key")
  const pageTitle = sessionStorage.getItem("pageTitle")

  useEffect(() => {
    if (!loaded) {
      if (props[0].location.state) {
        setExam(new Survey.Model(JSON.stringify(setQuestionVisibility(props[0].location.state.survey))));
        // German Translations
      }
      setLoaded(true);
    }
  }, [loaded, props]);

  useEffect(() => {
    if (exam) {
      var data = key
      exam.locale = language

      setTrainingTranslations(Survey, language);

      // set time left on training, this is done to avoid trivial cheating in a naive way.
      const timeLeft = sessionStorage.getItem("timeLeft");
      if (timeLeft) {
        exam.maxTimeToFinish = timeLeft;
      }

      // Write the time into the start page
      const timeToFinishElement = document.getElementById("time-to-finish");
      let timeToFinish = 0;

      // clamp timevalue
      if (timeToFinish < 0) {
        timeToFinish = 0;
      }

      getExamDataByKey(data)
        .then((res) => {
          const data = res;
          data.map((data) => {

            if (timeToFinishElement) {
              // its possible that there is no time set
              timeToFinish = data.examDuration

              if (sessionStorage.getItem("language") === "de" && timeToFinish > 0) {
                timeToFinishElement.innerHTML = `Du hast ${timeToFinish} Minuten Zeit. <br /> <br /> Wenn Du auf die Schaltfläche "Start" klickst beginnt Deine Prüfung.<br/>
              Dein Prüfungscode wird entwertet und Dein auf der vorherigen Seite eingegebener Name erscheint auf dem Leistungsnachweis. <br /> <br />
              Bitte lade die Seiten nicht neu, da damit die Prüfung abgebrochen und ungültig wäre. <br />
            Bitte nutze zur Navigation die Schaltfächen auf der Seite und keinesfalls die Vor- und Zurück-Buttons des Browsers. <br /> <br />
              Suche Dir einen ruhigen Platz mit stabiler Internetverbindung für die Prüfung. <br />
              Wir wünschen Dir viel Erfolg!`;
              } else if (timeToFinish > 0) {
                timeToFinishElement.innerHTML = `You have ${timeToFinish} minutes to finish.`;
              }
            }
          })

        })
        .catch((err) => {
          cogoToast.error(err);
        });

      /* // render how much time is left to finish training
      if (timeToFinishElement) {
        // its possible that there is no time set
        if (exam.maxTimeToFinish) timeToFinish = Math.floor(exam.maxTimeToFinish / 60);

        if (sessionStorage.getItem("language") === "de" && timeToFinish > 0) {
          timeToFinishElement.innerHTML = `Du hast ${timeToFinish} Minuten Zeit.`;
        } else if (timeToFinish > 0) {
          timeToFinishElement.innerHTML = `You have ${timeToFinish} minutes to finish.`;
        }
      } */

      // loading json questions from session storage
      loadState(exam);
    }
  }, [exam, key, language]);

  const saveState = (survey) => {
    let res = {
      currentPageNo: survey.currentPageNo,
      data: survey.data,
    };

    /* let response = {};
    for (let key in res.data) {
      if (res.data.hasOwnProperty(key)) {
        const question = survey.getQuestionByName(key);

        response[question.title] = question.value;
      }
    } */

    // Save input into session
    window.sessionStorage.setItem("training", JSON.stringify(res));
  };

  /**
   * method is triggered by onComplete SurveyJS event
   * TODO make sure that empty arrays get send to results route if no selectedChoices
   */
  const onTestComplete = (exam) => {
    saveState(exam);

    // Stoping the timer
    exam.stopTimer();
    const questionCount = exam.getQuizQuestionCount();
    const correctedAnswers = exam.getCorrectedAnswerCount();

    setCorrectPercentage((correctedAnswers / questionCount) * 100);

    // Training is done
    setIsCompleted(true);

    let result = { questions: [] };

    [exam.data].map((item) => {
      exam.getAllQuestions().forEach((question) => {
        if (!item[question.name]) {
          item[question.name] = "";
        }

        if (question.name !== "entry" && question.visible) {
          result.questions.push(
            generateQuestionResult(question, exam.data[question.name])
          );
        }
      });
      return item;
    });

    const { username, description, date } = props[0].location.state

    result["points"] = correctedAnswers;
    result["key"] = props[0].location.state.key
    result["collection"] = "exam";
    result["percentage"] = ((correctedAnswers / questionCount) * 100).toFixed(
      0
    );
    result["name"] = username
    result["description"] = description

    const now = new Date()
    const hours = now.getHours()
    const minutes = now.getMinutes()
    const timeLeft = sessionStorage.getItem('timeLeft')
    // const timeLeftInMinutes = timeLeft / 60
    // const timeLeftInSeconds = timeLeft / 60

    result["date"] = `${hours}:${minutes} Uhr ${date}`


    sessionStorage.setItem("answers", JSON.stringify(result));

    sendResult(result);
  };

  const onTestStarted = (options) => {
    setHasBegun(true);
    options.startTimer();
    uploadUsedKey(props[0].location.state.key).then((res) => {

    }).catch((err) => {
      console.log(err)
    });
  };

  /**
   * event is triggered every second if the method startTimer has been called.
   * @return the time in seconds end-user spends on the survey
   */
  const onTestTimer = (options) => {
    let root = document.getElementById("timer-progress");
    sessionStorage.setItem(
      "timeLeft",
      options.maxTimeToFinish - options.timeSpent
    );
    let timeLeftPercentage =
      ((options.maxTimeToFinish - options.timeSpent) /
        options.maxTimeToFinish) *
      100;
    root.style.setProperty("--timer-progress-width", timeLeftPercentage + "%");
  };

  const onPageChangeNumber = (training) => {
    let frage = training.currentPageNo;
    setNumber(++frage);
  }


  const trainingStarted = (hasBegun) ? (
    <div className="training-questions-counter"><h4>Frage {number} von {exam.getQuizQuestionCount()}</h4></div>
  ) : null;

  const testComponent =
    !isCompleted && exam !== null ? (
      <div id="surveyElement">
        {trainingStarted}
        <div id="timer-progress" className="timer-progress" />
        <Survey.Survey
          model={exam}
          showTitle={false}
          showPageNumbers={false}
          showTimerPanel={"none"}
          showProgressBar={"none"}
          onComplete={onTestComplete}
          onStarted={onTestStarted}
          onCurrentPageChanged={onPageChangeNumber}
          questionsOnPageMode={"questionPerPage"}
          onTimer={onTestTimer}
        />
      </div>
    ) : null;

  // Result Page
  const onCompleteComponent = isCompleted ? (
    <TrainingComplete
      resultPercentage={correctPercentage}
      surveyModel={JSON.stringify(exam)}
    />
  ) : null;

  const questionSelector =
    hasBegun && !isCompleted ? (
      <ExamQuestionSelection surveyModel={exam} />
    ) : null;

  const loadingAnim = !loaded ? (
    <div className={"loading"}>
      <ReactLoading
        type={"bubbles"}
        color={"#61bdaa"}
        height={300}
        width={100}
      />
    </div>
  ) : null;

  return (
    <div className={"training"}>
      <Title title={pageTitle} />
      {loadingAnim}
      {testComponent}
      {onCompleteComponent}
      {questionSelector}
    </div>
  );
};

export default Exam;
