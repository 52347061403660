import React from "react";
import Footer from "./Footer";
import Overview from "./Overview";
import Training from "./Training/Training";
import { Switch, Route, useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import Admin from "./Admin/Admin";
import NotFoundComponent from "./Misc/404";
import { AdminRoute } from "./Misc/AdminRoute";
import Login from "./Admin/Login";
import Results from "./Admin/Result/Results";
import TrainingEditor from "./Admin/Creator/TrainingEditor";
import Header from "./Admin/Header";
import Exam from "./Exam/Exam";
import ExamResults from "./Admin/Result/ExamResults";
import TrainingsContextProvider from "../context";

const Main = () => {

  const location = useLocation();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item: location, props, key }) => (

    <div key={key}>
      <TrainingsContextProvider>
        <Header />
        <section className="schulungen">
          <animated.div style={props} className="container">
            <Switch location={location}>

              <Route path='/login' component={Login} />

              <Route exact path="/" component={Overview} />
              <AdminRoute path="/admin/creator/:type" component={TrainingEditor} />
              <AdminRoute path="/admin/result" component={Results} />
              <AdminRoute path="/admin/exam_results" component={ExamResults} />
              <AdminRoute path="/admin" component={Admin} />
              <Route path="/training/:type" component={Training} />
              <Route path="/exam/:key" component={Exam} />

              <Route component={NotFoundComponent} />
            </Switch>
          </animated.div>
        </section>
        <Footer />
      </TrainingsContextProvider>
    </div>

  ));
};

export default Main;
