import { useCallback } from "react";
import { hasSession } from "./api";

export const setTrainingTranslations = (Survey, language) => {
    if (language === 'de') {
        Survey.emptySurvey = '';
        Survey.pageNextText = 'Weiter';
        Survey.pagePrevText = 'Zurück';
        Survey.completeText = 'Abschließen';
        // Survey.progressText = "Seite {0} von {1}";
    } else {
        Survey.pageNextText = 'Next';
        Survey.pagePrevText = 'Previous';
        Survey.completeText = 'Finish';
        // Survey.progressText = "Page {0} of {1}";
    }
};

export const loadState = (survey) => {
    // get session storage
    let storageSt = window
        .sessionStorage
        .getItem("training") || "";

    let res = {};
    // User has entered data
    if (storageSt)
        res = JSON.parse(storageSt);

    // fill survey with loaded data
    if (res.currentPageNo)
        survey.currentPageNo = res.currentPageNo;
    if (res.data)
        survey.data = res.data;
};

export const getTitleFromType = (trainings, type) => {
    const label = trainings.find(t => {
        return t.type == type
    })
    return label ? label.title : ''
}

export const checkSession = callback => {
    hasSession().then(res => {
        if (res == 200) {
            callback()
        }
    }).catch(err => {
        return false
    })
}

export const setQuestionChoicesOrder = trainingObj => {
    const parsedTrainingObj = JSON.parse(trainingObj)
    if (parsedTrainingObj.pages[1].elements && parsedTrainingObj.pages[1].elements.length > 0) {
        parsedTrainingObj.pages[1].elements.forEach(question => {
            question.choicesOrder = ''
        })
        return JSON.stringify(parsedTrainingObj)
    }
    return trainingObj
}

export const filterQuestionTypes = object => {
    // TODO: Refactor the types out of util functions
    // const correctTypes = ['boolean', 'checkbox', 'radiogroup']
    const incorrectTypes = ['comment']
    
    const questions = { ...object }
    questions.pages[1].elements.forEach((question, index) => {
        if (incorrectTypes.includes(question.type)) {
            questions.pages[1].elements.splice(index, 1)
        }
    })
    return questions
}
