import React from 'react';
import {Route, Redirect} from 'react-router-dom';

/** @class redirect to admin page once username has been set in localstorage */
export const AdminRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        localStorage.getItem('username')
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )}/>
);
