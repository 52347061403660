export const setSessionStorage = ({ privateKey, language, trainingName, name, dateToday, descriptionData }) => {
  if (privateKey) {
    sessionStorage.setItem("key", privateKey ? privateKey : null)
    sessionStorage.setItem("language", language ? language : null)
    sessionStorage.setItem("pageTitle", trainingName ? trainingName : null)
    sessionStorage.setItem("name", name ? name : null)
    sessionStorage.setItem("date", dateToday ? dateToday : null)
    sessionStorage.setItem(
      "description",
      descriptionData ? descriptionData : null
    )
  } else {
    sessionStorage.clear()
  }
}