import React from 'react';

/** @class uppercase title with green underline */
const Title = ({title}) => {
    return (
        <div className={"col-12"}>
            <div className={"title text-center"}>
                <h2>{title}</h2>
                <div className={"headline-border"}/>
            </div>
        </div>
    );
};

export default Title;
