export const reformatDateString = date => {
  const dateParts = date.split(".")
  return new Date('20' + dateParts[2], dateParts[1] - 1, dateParts[0]);
}

export const filterExpiredExams = exams => {
  return exams.filter(exam => {
    const validityDate = reformatDateString(exam.validity)
    if (!isNaN(validityDate)) {
      const currentDate = new Date()
      // JS new Date() returns a date with the actual time of the day but validityDate object hour time is always set to 00.00.00
      currentDate.setHours(0, 0, 0, 0)
      if (validityDate >= currentDate) {
        return exam
      }
    } return false
  })
}
