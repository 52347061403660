import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import deFlag from "../../../../images/de-flag.png";
import enFlag from "../../../../images/gb-flag.png";
import Switch from "react-switch";
import booleanIcon from "../../../../images/questions/icon-truefalse-white.svg"
import ReactTooltip from "react-tooltip";
import delIcon from "../../../../images/icon-trash-blue.svg";
import arrow from "../../../../images/arrow.svg";

const Boolean = ({ item, index, delQuestion, update }) => {
    const [type] = useState("boolean");
    const [name] = useState(uuidv4()); // generate uuid for name
    const [isExam, setIsExam] = useState(() => {
        if (item.isExam !== undefined) {
            return item.isExam
        } else {
            return true;
        }
    })
    const [isVisible, setIsVisible] = useState(() => {
        if (item.visible !== undefined) {
            return item.visible
        } else {
            return true;
        }
    });
    const [opacity, setOpacity] = useState(() => {
        if (item.visible !== undefined) { // check if isVisible is even set
            return !item.visible ? "25%" : "100%"
        } else {
            return "100%";
        }
    });
    const [isFolded, setIsFolded] = useState(false);
    const [isDefaultLanguage, setDefaultLanguage] = useState(true); // true: german, false: english
    const [title, setTitle] = useState({
        de: item.title.de,
        en: item.title.en
    });

    const [description, setDescription] = useState({
        de: item.description.de,
        en: item.description.en
    });

    // strings used in this component
    const [visibleString, setIsVisibleString] = useState("Sichtbar");
    const [titleString, setTitleString] = useState("Titel");
    const [descriptionString, setDescriptionString] = useState("Beschreibung");
    const [examString, setExamString] = useState("Prüfung");
    const [trueOrFalse, setTrueOrFalse] = useState("Wahr oder Falsch?");
    // why is the correctAnswer a string initally? SurveyJS (which renders the survey) uses a string as a correctAnswer in the background
    const [correctAnswer, setCorrectAnswer] = useState(() => {
        if (item.correctAnswer !== undefined) { // check if correctAnswer is even set
            return item.correctAnswer === "True"; // set correctAnswer accordingly to existing string
        } else {
            return false; // if no correctAnswer exists initally set to false
        }
    });
    const [arrowId] = useState(uuidv4());

    const handleLanguage = (e) => {
        setDefaultLanguage(e);

        // setting the basic strings of this component (title etc.)
        setIsVisibleString(isDefaultLanguage ? "Visible" : "Sichtbar");
        setTitleString(isDefaultLanguage ? "Title" : "Titel");
        setDescriptionString(isDefaultLanguage ? "Description" : "Beschreibung");
        setTrueOrFalse(isDefaultLanguage ? "True or False?" : "Wahr oder Falsch?");
        setExamString(isDefaultLanguage ? "Exam" : "Prüfung");
    };

    const handleIsVisible = (e) => {
        e.persist();
        const visible = e.target.checked;

        setOpacity(!visible ? "25%" : "100%");

        setIsVisible(visible);
    };

    const handleIsExam = (e) => {
        e.persist();
        const exam = e.target.checked;

        setIsExam(exam);

    };

    const handleTitle = (e) => {
        e.persist();
        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: title.en
            };
        } else {
            value = {
                de: title.de,
                en: e.target.value
            };
        }

        setTitle(value);
    };

    const handleDescription = (e) => {
        e.persist();

        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: description.en
            };
        } else {
            value = {
                de: description.de,
                en: e.target.value
            };
        }

        setDescription(value);
    };

    const handleCorrectAnswer = (e) => {
        setCorrectAnswer(e);
    };

    // init arrow rotation
    const handleFold = () => {
        const arrow = document.getElementById(arrowId.toString());
        if (isFolded) {
            arrow.style.transform = 'rotate(90deg)'
        } else {
            arrow.style.transform = 'rotate(-90deg)'
        }

        setIsFolded(!isFolded);
    };

    const toJSON = () => {
        return {
            "type": type,
            "name": name,
            "visible": isVisible,
            "isExam": isExam,
            "title": title,
            "description": description,
            "correctAnswer": correctAnswer ? "True" : "False",
            "labelTrue": {
                "de": "Wahr",
                "en": "True"
            },
            "labelFalse": {
                "de": "Falsch",
                "en": "False"
            }
        }
    };

    // Update the component
    useEffect(() => {
        update(toJSON(), index);
    }, [correctAnswer, title, description, isVisible, isExam]);

    const choicesComponent = (isFolded) ? (
        <div className={"expandable"}>
            <div className={"col-12 titles"}>
                <label>{descriptionString}</label>
                <input className={"col-12"} type={"text"}
                    value={isDefaultLanguage ? description.de : description.en} onChange={handleDescription} />
            </div>
            <div className={"col-12 bool-switch titles"}>
                <label>{trueOrFalse}</label>
                <Switch
                    id={"language"}
                    checked={correctAnswer}
                    onChange={handleCorrectAnswer}
                    offColor={"#3f3d3d"}
                    onColor={"#61bdaa"}
                    width={70}
                    height={25}
                    className={"switch"}
                />
            </div>
        </div>
    ) : null;

    return (
        <div style={{ opacity: opacity }}>
            <div className={"question-row"} onClick={handleFold}>
                <div className={"col-3"}>
                    {index}.
                </div>
                <div className={"col-6 question-type"}>
                    <div className={"text-center"}>
                        <img src={booleanIcon} alt={"radio"} />
                        <span>Wahr oder Falsch</span>
                    </div>
                </div>
                <div className={"col-3"}>
                    <img
                        data-tip={!isFolded ? "Antwortmöglichkeiten ausklappen" : "Antwortmöglichkeiten einklappen"}
                        className={"arrow"} id={arrowId} src={arrow} alt={"arrow"} />
                    <ReactTooltip />
                </div>
            </div>
            <div className={"editable-question"}>
                <div className={"radiogroup row"}>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isVisible"} checked={isVisible} onChange={handleIsVisible} />
                        <label htmlFor={"isVisible"}>{visibleString}</label>
                    </div>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isExam"} checked={isExam} onChange={handleIsExam} />
                        <label htmlFor={"isExam"}>{examString}</label>
                    </div>

                    <div className={"col-3 language-switch"}>
                        <Switch
                            id={"language"}
                            checked={isDefaultLanguage}
                            onChange={handleLanguage}
                            offColor={"#3f3d3d"}
                            onColor={"#3f3d3d"}
                            uncheckedIcon={<img src={enFlag} alt="en" />}
                            checkedIcon={<img src={deFlag} alt="de" />}
                            width={70}
                            height={25}
                        />
                    </div>
                    <div className={"col-3 del-btn"}>
                        <button data-tip={"Frage löschen"} onClick={() => {
                            // This is a quick solution: might actually spend the time to make a decent confirmation component
                            let r = window.confirm("Bist du sicher das du diese Frage löschen möchtest?");
                            if (r === true) {
                                delQuestion(index - 1)
                            }
                        }}>
                            <img src={delIcon} alt={"add"} />
                        </button>
                        <ReactTooltip />
                    </div>
                    <div className={"col-12 titles"}>
                        <label>{titleString}</label>
                        <input className={"col-12"} type={"text"} value={isDefaultLanguage ? title.de : title.en}
                            onChange={handleTitle} />
                    </div>
                    {choicesComponent}
                </div>
            </div>
        </div>
    )
};
export default Boolean;