import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { TrainingsContext } from '../../context';
import "./DatePicker.css"

const NewTraining = (props) => {
    const [selection, setSelection] = useState(null);

    const { data: { trainingLabels } } = useContext(TrainingsContext)

    const elements = trainingLabels?.map(obj => {
        return {
            value: obj.type,
            label: obj.title
        }
    })

    const customStyle = {
        option: provided => ({
            ...provided,
            color: 'white',
            borderBottom: '1px solid #3f3d3d',
            padding: 15,
            backgroundColor: '#4c4c4c',
            fontSize: 20,

            '&:hover': {
                backgroundColor: '#6b6b6b'
            }
        }),
        control: base => ({
            ...base,
            color: 'white',
            fontSize: 20,
            backgroundColor: '#4c4c4c',
            boxShadow: "none",
            height: 50,
            minHeight: 50,

            '&:hover': {
                borderColor: '#A9F7E3'
            },

            '&:focus': {
                outlineColor: '#A9F7E3'
            }
        }),
        menu: base => ({
            ...base,
            backgroundColor: '#4c4c4c',
        }),
        valueContainer: base => ({
            ...base,
            height: 50
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            const color = 'white';

            return { ...provided, opacity, color, transition };
        }
    };

    const onTypeSelection = (selected) => {
        setSelection(selected);
    };

    return (
        <div className="col-sm-5 interaction-containers new-training">
            <h4 className="text-left">Übungen bearbeiten</h4>
            <Select
                styles={customStyle}
                className="type-selector"
                options={elements}
                placeholder="Themengebiet"
                onChange={onTypeSelection}
                value={selection}
                isSearchable={false}
            />
            {((selection === null) ?
                (<button className="btn btn-transparent" disabled>
                    Bearbeiten
                </button>) : (
                    <Link to={`admin/creator/${selection.value}`} className="btn btn-transparent">
                        Bearbeiten
                    </Link>))}
        </div>
    );
}

export default NewTraining;