import cogoToast from "cogo-toast";
import React from "react";
import { useHistory } from "react-router-dom";
import { getResultsForKey } from "../../utils/api";

const PreviousTraining = ({ ...props }) => {

  const history = useHistory()

  const fetchResults = () => {
    getResultsForKey(props.item.training)
      .then(res => {
        history.push({
          pathname: 'admin/result',
          state: {
            key: props.item.training,
            results: res.resultList.results ? res.resultList.results : [],
            averageResults: res.averageResultsArray ? res.averageResultsArray : [],
            origin: 'training'
          }
        })
      }).catch(err => {
        console.log(err)
        if (err?.response?.status == 404) {
          history.push({
            pathname: 'admin/result',
            state: {
              key: props.item.training,
              results: [],
              averageResults: [],
              origin: 'training'
            }
          })
        } else {
          cogoToast.error('Ein Fehler ist aufgetreten')
        }
      })
  }

  const key =
    props.item.training === "exam" ? (
      <div>
        <span>Schlüssel: {props.item.key}</span>
        <br />
        <span>{props.item.date}</span>
      </div>
    ) : null;

  return (
    <div className="row interaction-container training">
      <div className="col-sm-4 col-lg-8">
        <h5>{props.item.title}</h5>
        {key}
      </div>
      <button
        className="btn btn-transparent col-sm-8 col-lg-4"
        onClick={fetchResults}
      >Ergebnisse</button>
    </div>
  );
};

export default PreviousTraining;
