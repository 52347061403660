import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Title from "../../Misc/Title";
import Switch from "react-switch";
import deFlag from "../../../images/de-flag.png";
import enFlag from "../../../images/gb-flag.png";
import visibleFlag from "../../../images/visible.svg"
import invisibleFlag from "../../../images/invisible.svg"

import { getTrainingAvailable, sendTrainingAvailable, deleteTrainingAvailable } from "../../../utils/api";

const Settings = ({ open, closeModal, maxTime, handleMaxTime, title, handleTitle, introText, introTextEnglish, handleIntroText, trainingType, handleSave, handleVisibility, visible, handleIntroTextEnglish }) => {
    const [isDefaultLanguage, setDefaultLanguage] = useState(true); // true: german, false: english
    const [titleString, setTitleString] = useState("Titel");
    const [settingsString, setSettingsString] = useState("Einstellungen");
    const [introductionString, setIntroductionString] = useState("Einleitungstext");
    const [timeString, setTimeString] = useState("Zeit für die Übung in Sekunden");
    const [isVisible, setIsVisible] = useState(visible)

    const handleLanguage = (e) => {
        setTitleString(isDefaultLanguage ? "Title" : "Titel");
        setSettingsString(isDefaultLanguage ? "Settings" : "Einstellungen");
        setIntroductionString(isDefaultLanguage ? "Introduction" : "Einleitungstext");
        setTimeString(isDefaultLanguage ? "Time for the training in seconds" : "Zeit für die Übung in Sekunden");

        setDefaultLanguage(e);
    };

    let active = {}

    const handleIsVisible = e => {
        handleVisibility({ e, active })
        setIsVisible(e)
        active["active"] = trainingType
    }

    const setMaxTime = (e) => {
        e.persist();

        handleMaxTime(e.target.value)
    };

    const setTitle = (e) => {
        e.persist();

        handleTitle(e.target.value, isDefaultLanguage);
    };

    const setIntroText = (e) => {
        e.persist();
        if (isDefaultLanguage) {
            handleIntroText(e.target.value);
        } else {
            handleIntroTextEnglish(e.target.value)
        }
    };


    const handleModalSave = () => {
        const test = isVisible
        handleSave(closeModal, test)
    }

    return (
        <Modal
            isOpen={open}
            contentLabel="settings"
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeModal}
            className="editor-settings"
            overlayClassName="settings-overlay"
            ariaHideApp={false}
        >
            <div className={"row"}>
                <div className={"col-11"} />
                <button className={"btn col-1"} onClick={closeModal}>X</button>
            </div>
            <Title title={settingsString} />
            <div className={"col-12 language-switch"}>
                <Switch
                    id={"language"}
                    checked={isDefaultLanguage}
                    onChange={handleLanguage}
                    offColor={"#3f3d3d"}
                    onColor={"#3f3d3d"}
                    checkedIcon={<img src={deFlag} alt={"de"} />}
                    uncheckedIcon={<img src={enFlag} alt={"en"} />}
                    width={70}
                    height={25}
                />
            </div>
            <br />
            <div className={"col-12 language-switch"}>
                <Switch
                    id={"isVisible"}
                    checked={visible}
                    onChange={handleIsVisible}
                    offColor={"#3f3d3d"}
                    onColor={"#3f3d3d"}
                    checkedIcon={<img src={visibleFlag} alt={"visible"} />}
                    uncheckedIcon={<img src={invisibleFlag} alt={"not visible"} />}
                    width={70}
                    height={25}
                />
            </div>
            <br />
            <div className={"col-12"}>
                <label>{titleString}</label>
                <br />
                <input value={isDefaultLanguage ? title.de : title.en} onChange={setTitle} type={"text"} placeholder={"SCRUM ..."} />
            </div>
            <div className={"col-12"}>
                <label>{introductionString}</label>
                <br />
                <input value={isDefaultLanguage ? introText : introTextEnglish} onChange={setIntroText} className={"intro"} type={"text"}
                    placeholder={isDefaultLanguage ? "Hier beantworten sie Fragen zu Thema XY..." : "You will have to answer various question about the topic XY..."} />
            </div>
            <div className={"col-12"}>
                <label>{timeString}</label>
                <br />
                <input value={maxTime} onChange={setMaxTime} type={"number"} placeholder={"15"} />
            </div>
            <div>
                <button
                    className={"btn btn-modal btn-transparent"}
                    onClick={handleModalSave}>
                    Fragenpool aktualisieren
                </button>
            </div>
        </Modal>
    )
};

export default Settings;