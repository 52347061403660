import React, { useContext } from 'react';
import Title from "../Misc/Title";
import PreviousTrainings from "./PreviousTrainings";
import NewExam from "./NewExam";
import NewTraining from "./NewTraining";
import PreviousExams from "./PreviousExams"
import { TrainingsContext } from '../../context';

const Admin = () => {
    return (
        <div>
            <div className="admin">
                <Title title="Administration Übungen" />
                <div className="row">
                    <NewTraining />
                    <div className="col-1" />
                    <PreviousTrainings />
                </div>
            </div>
            <br />
            <div className="admin">
                <Title title="Administration Prüfungen" />
                <div className="row">
                    <NewExam />
                    <div className="col-1" />
                    <PreviousExams />
                </div>
            </div>


        </div>
    );
};

export default Admin;
