import React, {useState} from 'react';

const AddQuestion = ({type, defaultQuestion}) => {
    const [icon] = useState(() => {
        switch (type) {
            case "radiogroup":
                return <svg version="1.1"
                            viewBox="0 0 35 35" width="20" height="20">
                    <path fill="#ffffff" id="radio" className="st0" d="M4.1,22.9c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1S0,29.2,0,27C0,24.7,1.8,22.9,4.1,22.9z
                             M4.1,24.9C2.9,24.9,2,25.8,2,27s0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1S5.3,24.9,4.1,24.9z M35,26c0.6,0,1,0.4,1,1s-0.4,1-1,1H12.9
                            c-0.6,0-1-0.4-1-1s0.4-1,1-1H35z M4.1,11.9c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1S0,18.3,0,16C0,13.7,1.8,11.9,4.1,11.9z
                             M4.1,13.9C2.9,13.9,2,14.8,2,16s0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1S5.3,13.9,4.1,13.9z M35,15c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1H12.9
                            c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1H35z M4.1,0.9c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1S0,7.3,0,5C0,2.8,1.8,0.9,4.1,0.9z
                             M4.1,2.9C2.9,2.9,2,3.9,2,5s0.9,2.1,2.1,2.1S6.2,6.2,6.2,5S5.3,2.9,4.1,2.9z M35,4c0.6,0,1,0.4,1,1s-0.4,1-1,1H12.9
                            c-0.6,0-1-0.4-1-1s0.4-1,1-1H35z"/>
                </svg>;
            case "checkbox":
                return <svg version="1.1" viewBox="0 0 39 39" width="20" height="20">
                    <path fill="#ffffff" id="checkbox" className="st0" d="M27.8,0.8c4,0,7.3,3.3,7.3,7.4v19.6c0,4.1-3.3,7.4-7.3,7.4H8.2c-4,0-7.3-3.3-7.3-7.4V8.2
                        c0-4.1,3.3-7.4,7.3-7.4H27.8z M27.8,2.8H8.2c-2.9,0-5.3,2.4-5.3,5.4v19.6c0,3,2.4,5.4,5.3,5.4h19.6c2.9,0,5.3-2.4,5.3-5.4V8.2
                        C33.2,5.3,30.8,2.8,27.8,2.8z M28.6,10.6c0.4,0.4,0.4,1,0,1.4L14.5,26.1l-7.2-7.2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l5.8,5.8
                        l12.7-12.7C27.6,10.2,28.3,10.2,28.6,10.6z"/>
                </svg>;
            case "boolean":
                return <svg version="1.1" viewBox="0 0 39 39" width="20" height="20">
                    <path fill="#ffffff" id="boolean" className="st0" d="M30.8,0c3,0,5.5,2.5,5.5,5.5l0,0v13.8c0,3-2.5,5.5-5.5,5.5l0,0h-5.9v5.9c0,3-2.5,5.5-5.5,5.5l0,0
                        H5.5c-3,0-5.5-2.5-5.5-5.5l0,0V16.9c0-3,2.5-5.5,5.5-5.5l0,0h5.9V5.5c0-3,2.5-5.5,5.5-5.5l0,0H30.8z M19.3,13.4H5.5
                        C3.6,13.4,2,15,2,16.9l0,0v13.8c0,1.9,1.6,3.5,3.5,3.5l0,0h13.9c1.9,0,3.5-1.6,3.5-3.5l0,0V16.9C22.8,15,21.3,13.4,19.3,13.4
                        L19.3,13.4z M18.7,18.4c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l0,0L10,30l-5.3-5.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l0,0
                        l3.9,3.9L18.7,18.4z M30.8,2H16.9c-1.9,0-3.5,1.6-3.5,3.5l0,0v5.9h5.9c3,0,5.5,2.5,5.5,5.5l0,0v5.9h5.9c1.9,0,3.5-1.6,3.5-3.5l0,0
                        V5.5C34.2,3.6,32.7,2,30.8,2L30.8,2z"/>
                </svg>;
            default:
                break;
        }
    });

    const addQuestion = () => {
        defaultQuestion(type);
    };

    return (
        <button onClick={addQuestion} className={"btn btn-transparent add-question"}>
            {icon}
        </button>
    )
};

export default AddQuestion;