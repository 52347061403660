import React from "react";
import {useLocation} from "react-router-dom";
import logo from '../../images/t2m-logo.svg'
import LogoutButton from "./LogoutButton";

const Header = () => {
    const location = useLocation();

    const adminButton = (location.pathname !== "/admin") ?
        (<div className={"admin-btn"}>
            <a className={"btn btn-transparent"} href={"/#/admin"}>
                Admin Übersicht
            </a>
        </div>) : null;

    return ((location.pathname.includes("/admin")) ?
            (<header>
                <div className={"container admin-nav"}>
                    <a href={'/'}>
                        <img className={"logo"} src={logo} alt="t2m-logo"/>
                    </a>
                    {adminButton}
                    <div className={"right-btn"}>
                        <LogoutButton/>
                    </div>
                </div>
            </header>) : null
    )
};

export default Header;