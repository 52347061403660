import React, { useEffect, useContext } from 'react';
import PreviousTraining from "./PreviousTraining";
import { getPreviousTrainings } from "../../utils/api";
import { TrainingsContext } from '../../context';

// component on the right of the admin page, with list of previous trainings and button to results
const PreviousTrainings = (props) => {

    const { data: { trainingLabels, previousTrainings } } = useContext(TrainingsContext)

    return (
        <div id="previous-trainings" className="col-sm-6 interaction-container previous-trainings">
            <h4 className="text-left col-12">Übungen</h4>
            {trainingLabels?.map(label => {
                return (
                    <PreviousTraining key={label.type} item={{ key: label.type, training: label.type, title: label.title }} />
                )
            })}
        </div>
    );
};

export default PreviousTrainings;