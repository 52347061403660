import React from 'react';
import logoutIcon from "../../images/icon-log-out.svg";

const LogoutButton = () => {
    const logout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("access_token");
        window.location.reload();
    };

    return (
        <button onClick={logout} className={"logout btn btn-transparent"}>
            Abmelden
            <img src={logoutIcon} alt={"logout"}/>
        </button>
    );
};

export default LogoutButton;