import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import addIcon from '../../../../images/icon-add-element.svg'
import delIcon from "../../../../images/icon-trash-blue.svg";
import arrow from "../../../../images/arrow.svg";
import deFlag from "../../../../images/de-flag.png";
import enFlag from "../../../../images/gb-flag.png";
import ReactTooltip from "react-tooltip";
import Switch from "react-switch";
import checkboxIcon from "../../../../images/questions/icon-singlecheck-white.svg";

const Checkbox = ({ item, index, delQuestion, update }) => {
    const [type] = useState("checkbox");
    const [name] = useState(uuidv4()); // generate uuid for name
    const [isExam, setIsExam] = useState(() => {
        if (item.isExam !== undefined) {
            return item.isExam
        } else {
            return true;
        }
    });
    const [isVisible, setIsVisible] = useState(() => {
        if (item.visible !== undefined) {
            return item.visible
        } else {
            return true;
        }
    });
    const [opacity, setOpacity] = useState(() => {
        if (item.visible !== undefined) { // check if isVisible is even set
            return !item.visible ? "25%" : "100%"
        } else {
            return "100%";
        }
    });
    const [isFolded, setIsFolded] = useState(false);
    const [isDefaultLanguage, setDefaultLanguage] = useState(true); // true: german, false: english
    const [title, setTitle] = useState({
        de: item.title.de,
        en: item.title.en
    });

    const [description, setDescription] = useState({
        de: item.description.de,
        en: item.description.en
    });

    const [choices, setChoices] = useState(item.choices);

    // strings used in this component
    const [visibleString, setIsVisibleString] = useState("Sichtbar");
    const [titleString, setTitleString] = useState("Titel");
    const [descriptionString, setDescriptionString] = useState("Beschreibung");
    const [decisionString, setDecisionString] = useState("Auswahlmöglichkeiten");
    const [examString, setExamString] = useState("Prüfung");

    const getCorrectAnswerIndices = () => {
        let correctAnswers = new Set([]);

        // check if correctAnswer has already been set
        if (item.correctAnswer) {
            for (let i = 0; i < choices.length; i++) {
                for (let j = 0; j < item.correctAnswer.length; j++) {
                    if (item.correctAnswer[j] === choices[i].value) {
                        correctAnswers.add(i);
                    }
                }
            }
            return correctAnswers;
        } else {
            return correctAnswers;
        }
    };

    const getCorrectAnswers = () => {
        let indices = new Set([...correctAnswers]);
        let answers = [];

        indices.forEach((i) => {
            if (choices[i] !== undefined) {
                answers.push(choices[i].value);
            }
        });

        return answers;
    };

    const [correctAnswers, setCorrectAnswers] = useState(getCorrectAnswerIndices());
    const [correctAnswer, setCorrectAnswer] = useState(getCorrectAnswers());
    const [arrowId] = useState(uuidv4());

    const handleCorrectAnswerChange = (e, index) => {
        let indices = new Set([...correctAnswers]);

        if (indices.has(index)) {
            indices.delete(index);
        } else {
            indices.add(index);
        }

        setCorrectAnswers(indices);
        let answers = [];

        indices.forEach((i) => {
            if (choices[i] !== undefined) {
                answers.push(choices[i].value);
            }
        });

        setCorrectAnswer(answers);
    };

    const handleLanguage = (e) => {
        setDefaultLanguage(e);

        // setting the basic strings of this component (title etc.)
        setIsVisibleString(isDefaultLanguage ? "Visible" : "Sichtbar");
        setTitleString(isDefaultLanguage ? "Title" : "Titel");
        setDescriptionString(isDefaultLanguage ? "Description" : "Beschreibung");
        setDecisionString(isDefaultLanguage ? "Choices" : "Auswahlmöglichkeiten");
        setExamString(isDefaultLanguage ? "Exam" : "Prüfung");
    };

    const handleIsVisible = (e) => {
        e.persist();
        const visible = e.target.checked;

        setOpacity(!visible ? "25%" : "100%");

        setIsVisible(visible);
    };

    const handleIsExam = (e) => {
        e.persist();
        const exam = e.target.checked;

        setIsExam(exam);
    };

    const handleTitle = (e) => {
        e.persist();
        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: title.en
            };
        } else {
            value = {
                de: title.de,
                en: e.target.value
            };
        }

        setTitle(value);
    };

    const handleDescription = (e) => {
        e.persist();

        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: description.en
            };
        } else {
            value = {
                de: description.de,
                en: e.target.value
            };
        }

        setDescription(value);
    };

    const handleChoiceEdit = (e, index) => {
        e.persist();

        let listOfChoices = [...choices];
        if (isDefaultLanguage) {
            listOfChoices[index] = { "value": e.target.value, "text": { "en": listOfChoices[index].text.en } };
        } else {
            listOfChoices[index] = { "value": listOfChoices[index].value, "text": { "en": e.target.value } };
        }

        setChoices(listOfChoices);

        let indices = new Set([...correctAnswers]);

        let answers = [];

        indices.forEach((i) => {
            if (listOfChoices[i] !== undefined) {
                answers.push(listOfChoices[i].value);
            }
        });

        setCorrectAnswer(answers);
    };

    const addChoice = (e) => {
        e.preventDefault();
        let tempChoices = [...choices];

        tempChoices.push({
            value: "de",
            text: {
                en: "en"
            }
        });

        setChoices(tempChoices);
    };

    // init arrow rotation
    const handleFold = () => {
        const arrow = document.getElementById(arrowId.toString());
        if (isFolded) {
            arrow.style.transform = 'rotate(90deg)'
        } else {
            arrow.style.transform = 'rotate(-90deg)'
        }

        setIsFolded(!isFolded);
    };

    const toJSON = () => {
        return {
            "type": type,
            "name": name,
            "visible": isVisible,
            "isExam": isExam,
            "title": title,
            "description": description,
            "choices": choices,
            "correctAnswer": correctAnswer,
            "choicesOrder": "random"
        }
    };

    // Update the component
    useEffect(() => {
        update(toJSON(), index);
    }, [title, description, isVisible, isExam, choices, correctAnswer, correctAnswers]);

    const choicesComponent = (isFolded) ? (
        <div className={"expandable"}>
            <div className={"col-12 titles"}>
                <label>{descriptionString}</label>
                <input className={"col-12"} type={"text"}
                    value={isDefaultLanguage ? description.de : description.en} onChange={handleDescription} />
            </div>
            <form className={"col-12 titles"}>
                <span>{decisionString}</span>
                {
                    choices.map((item, i) => {
                        return <div key={i} className={"choices"}>
                            <input data-tip="Richtige Antwort" type={"checkbox"} className={"col-1"}
                                checked={correctAnswers.has(i)}
                                onChange={(event) => handleCorrectAnswerChange(event, i)}
                            />
                            <ReactTooltip />
                            <input type={"text"} className={"col-10"}
                                value={isDefaultLanguage ? item.value : item.text.en} key={i}
                                onChange={(event) => {
                                    handleChoiceEdit(event, i);
                                }} />
                            <button data-tip="Antwort löschen" className={"col-1"} onClick={(e) => {
                                e.preventDefault();
                                let tempChoices = [...choices];
                                // Delete answer
                                tempChoices.splice(i, 1);
                                setChoices(tempChoices);
                            }}>
                                <img src={addIcon} alt={"add"} />
                            </button>
                            <ReactTooltip />
                        </div>
                    })
                }

                <button data-tip="Auswahlmöglichkeit hinzufügen" onClick={addChoice}
                    className={"btn btn-transparent add-btn"}>
                    <img src={addIcon} alt={"add"} />
                </button>
                <ReactTooltip />
            </form>
        </div>
    ) : null;

    return (
        <div style={{ opacity: opacity }}>
            <div className={"question-row"} onClick={handleFold}>
                <div className={"col-3"}>
                    {index}.
                </div>
                <div className={"col-6 question-type"}>
                    <div className={"text-center"}>
                        <img src={checkboxIcon} alt={"radio"} />
                        <span>Mehrfachauswahl</span>
                    </div>
                </div>
                <div className={"col-3"}>
                    <img
                        data-tip={!isFolded ? "Antwortmöglichkeiten ausklappen" : "Antwortmöglichkeiten einklappen"}
                        className={"arrow"} id={arrowId} src={arrow} alt={"arrow"} />
                    <ReactTooltip />
                </div>
            </div>
            <div className={"editable-question"}>
                <div className={"radiogroup row"}>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isVisible"} checked={isVisible} onChange={handleIsVisible} />
                        <label htmlFor={"isVisible"}>{visibleString}</label>
                    </div>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isExam"} checked={isExam} onChange={handleIsExam} />
                        <label htmlFor={"isExam"}>{examString}</label>
                    </div>

                    <div className={"col-3 language-switch"}>
                        <Switch
                            id={"language"}
                            checked={isDefaultLanguage}
                            onChange={handleLanguage}
                            offColor={"#3f3d3d"}
                            onColor={"#3f3d3d"}
                            uncheckedIcon={<img src={enFlag} alt="en" />}
                            checkedIcon={<img src={deFlag} alt="de" />}
                            width={70}
                            height={25}
                        />
                    </div>
                    <div className={"col-3 del-btn"}>
                        <button data-tip={"Frage löschen"} onClick={() => {
                            // This is a quick solution: might actually spend the time to make a decent confirmation component
                            var r = window.confirm("Bist du sicher das du diese Frage löschen möchtest?");
                            if (r === true) {
                                delQuestion(index - 1)
                            }
                        }}>
                            <img src={delIcon} alt={"add"} />
                        </button>
                        <ReactTooltip />
                    </div>
                    <div className={"col-12 titles"}>
                        <label>{titleString}</label>
                        <input className={"col-12"} type={"text"} value={isDefaultLanguage ? title.de : title.en}
                            onChange={handleTitle} />
                    </div>
                    {choicesComponent}
                </div>
            </div>
        </div>
    )
}
    ;

export default Checkbox;