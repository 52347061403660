import React from 'react';
import { Link } from "react-router-dom";

const PreviousExam = ({ ...props }) => {

    const title = props.item.description;

    const key = (props.item.training === "exam") ? (
        <div>
            <span>Teilnehmer: {props.item.candidates}</span>
            <br />
        </div>
    ) : null;

    return (
        <div className={"interaction-container training row"}>
            <div className={"col-sm-4 col-lg-6"}>
                <h5>{title}</h5>
                {key}
            </div>
            <Link className={"btn btn-transparent col-sm-4 col-lg-3"} to={`/admin/exam_results?key=${props.item.description}`}>
                Mehr Anzeigen
            </Link>
            <button
                className={"btn btn-transparent col-sm-3 col-lg-2 ml-2"}
                onClick={() => props.handleExamDelete(props.item._id)}
            >
                Löschen
            </button>
        </div>
    );
};

export default PreviousExam;