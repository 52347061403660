import React from 'react';
import notFound from '../../images/404.png'

/** @class generic 404 page */
const NotFoundComponent = () => {
    return (
        <div className="text-center not-found">
            <img className={"img-fluid"} src={notFound} alt={"not-found"}/>
            <div className={"col-12"}>
                <a href={'/'} className={"btn btn-transparent"}>Zurück</a>
            </div>
        </div>
    );
};

export default NotFoundComponent;