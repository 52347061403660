import { TrainingsContext } from '../context'
import TrainingSelection from "./TrainingSelection";
import Title from "./Misc/Title";
import ExamSelection from "./ExamSelection";
import React, { useContext } from "react";


const Overview = () => {
    // clear timeLeft in session in case a user returns to the overview from a training
    sessionStorage.removeItem("timeLeft");

    const { data: { activeTrainings } } = useContext(TrainingsContext)

    return (
        <div>
            <Title title="Übungen" />
            <div className="row">
                {activeTrainings?.map((training, index) =>
                    <div className="col-md-6 col-lg-4 text-center" key={index}>
                        <TrainingSelection trainingName={training.title} type={training.type} />
                    </div>
                )}
            </div>
            <div className="row">
                <Title title="Prüfung" />
                <div className="col-md-6 col-lg-4 text-center"></div>
                <div className="col-md-6 col-lg-4 text-center">
                    <ExamSelection isExam={true} trainingName="Prüfung" type="exam" />
                </div>
            </div>
        </div>
    );
};

export default Overview;
