import React from 'react';

const Legend = ({mainColor, secondaryColor}) => {
    return (
        <div className={"legend col-12 col-sm-12 col-md-4"}>
            <div className={"info-row row info"}>
                <div className={"color-box col-3"} style={{backgroundColor: secondaryColor}}/>
                <span className={"col-9"}>
                    Richtige Antworten
                </span>
            </div>
            <div className={"info-row row"}>
                <div className={"color-box col-3"} style={{backgroundColor: mainColor}}/>
                <span className={"col-9"}>
                    Falsche Antworten
                </span>
            </div>
        </div>
    );
};

export default Legend;
