import React, { useEffect, useState } from 'react';
import TrainingResults from "./TrainingResults";
import { setSessionStorage } from '../../utils/sessionStorage';
import arrow from "../../images/arrow.svg";
import { Link } from 'react-router-dom';

const TrainingComplete = ({ resultPercentage, surveyModel }) => {
    const [displayAnswers, setShowAnswers] = useState(false); // show correct answers
    const [resultText, setResultText] = useState("");
    const [btnText, setBtnText] = useState("");
    const [btnReturnText, setBtnReturnText] = useState("")

    const language = sessionStorage.getItem("language")

    useEffect(() => {
        if (language === "de") {
            setResultText("Du hast " + resultPercentage.toFixed(0) + "% korrekt beantwortet.");
            setBtnReturnText("Zurück zur Startseite")
            if (!displayAnswers) {
                setBtnText("Antworten sehen");
                document.getElementById("arrow").style.transform = "rotate(90deg)"

            } else {
                setBtnText("Antworten verstecken");
                document.getElementById("arrow").style.transform = "rotate(-90deg)"
            }
        } else if (language === "en") {
            setResultText("You have answered " + resultPercentage.toFixed(0) + "% correctly.");
            setBtnReturnText("Back to home page")
            if (!displayAnswers) {
                setBtnText("See answers");
                document.getElementById("arrow").style.transform = "rotate(90deg)"
            } else {
                setBtnText("Hide answers");
                document.getElementById("arrow").style.transform = "rotate(-90deg)"
            }
        }
    }, [resultPercentage, displayAnswers]);

    const toggleAnswers = () => {
        setShowAnswers(!displayAnswers)
    }

    const trainingResults = (displayAnswers) ? (
        <TrainingResults surveyJSON={surveyModel} />
    ) : null;


    return (
        <div className={"training-complete"}>
            <div className={"text-center"}>
                <p>
                    {resultText}
                </p>
                <div className={"col-12"}>
                    <button className={"btn btn-transparent"} onClick={toggleAnswers}>
                        {btnText}
                        <img id="arrow" src={arrow} alt={"login"} />
                    </button>
                </div>
            </div>
            <br />
            {trainingResults}
            <div className={"training-complete"}>
                <div className={"text-center"}>
                    <Link to={"/"}>
                        <button className={"btn btn-transparent btn-startseite"} onClick={setSessionStorage}>{btnReturnText}
                        </button>
                    </Link>
                </div>
            </div>
        </div>


    );
};

export default TrainingComplete;
