import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import addIcon from '../../../../images/icon-add-element.svg'
import delIcon from "../../../../images/icon-trash-blue.svg";
import arrow from "../../../../images/arrow.svg";
import deFlag from "../../../../images/de-flag.png";
import enFlag from "../../../../images/gb-flag.png";
import ReactTooltip from "react-tooltip";
import Switch from "react-switch";
import radioIcon from "../../../../images/questions/icon-multiplechoice-white.svg"

// TODO checkbox and radio styling doesnt work on firefox and safari
const RadioGroup = ({ item, index, delQuestion, update }) => {
    const [type] = useState("radiogroup");
    const [name] = useState(uuidv4()); // generate uuid for name
    const [isExam, setIsExam] = useState(() => {
        if (item.isExam !== undefined) {
            return item.isExam
        } else {
            return true;
        }
    });

    const [isVisible, setIsVisible] = useState(() => {
        if (item.visible !== undefined) {
            return item.visible
        } else {
            return true;
        }
    });
    const [opacity, setOpacity] = useState(() => {
        if (item.visible !== undefined) { // check if isVisible is even set
            return !item.visible ? "25%" : "100%"
        } else {
            return "100%";
        }
    });
    const [isFolded, setIsFolded] = useState(false);
    const [isDefaultLanguage, setDefaultLanguage] = useState(true); // true: german, false: english
    const [title, setTitle] = useState({
        de: item.title.de,
        en: item.title.en
    });

    const [description, setDescription] = useState({
        de: item.description.de,
        en: item.description.en
    });

    const [choices, setChoices] = useState(item.choices);

    // strings used in this component
    const [visibleString, setIsVisibleString] = useState("Sichtbar");
    const [titleString, setTitleString] = useState("Titel");
    const [descriptionString, setDescriptionString] = useState("Beschreibung");
    const [decisionString, setDecisionString] = useState("Auswahlmöglichkeiten");
    const [examString, setExamString] = useState("Prüfung")

    const getCorrectAnswerIndex = () => {
        // a correctAnswer already exists
        if (item.correctAnswer) {
            for (let i = 0; i < choices.length; i++) {
                if (item.correctAnswer === choices[i].value) {
                    return i;
                }
            }
        }
    };

    const getInitialCorrectAnswer = () => {
        if (item.correctAnswer) {
            return item.correctAnswer
        } else {
            return "0";
        }
    };

    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(getCorrectAnswerIndex());
    const [correctAnswer, setCorrectAnswer] = useState(getInitialCorrectAnswer());
    const [arrowId] = useState(uuidv4());

    const handleCorrectAnswerChange = (index, item) => {
        setCorrectAnswerIndex(index);
        setCorrectAnswer(item.value);
    };

    const handleLanguage = (e) => {
        setDefaultLanguage(e);

        // setting the basic strings of this component (title etc.)
        setIsVisibleString(isDefaultLanguage ? "Visible" : "Sichtbar");
        setTitleString(isDefaultLanguage ? "Title" : "Titel");
        setDescriptionString(isDefaultLanguage ? "Description" : "Beschreibung");
        setExamString(isDefaultLanguage ? "Exam" : "Prüfung");
        setDecisionString(isDefaultLanguage ? "Choices" : "Auswahlmöglichkeiten");
    };

    const handleIsVisible = (e) => {
        e.persist();
        const visible = e.target.checked;

        setOpacity(!visible ? "25%" : "100%");

        setIsVisible(visible);
    };

    const handleIsExam = (e) => {
        e.persist();
        const exam = e.target.checked;

        setIsExam(exam);
    };


    const handleTitle = (e) => {
        e.persist();
        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: title.en
            };
        } else {
            value = {
                de: title.de,
                en: e.target.value
            };
        }

        setTitle(value);
    };

    const handleDescription = (e) => {
        e.persist();

        let value;
        if (isDefaultLanguage) {
            value = {
                de: e.target.value,
                en: description.en
            };
        } else {
            value = {
                de: description.de,
                en: e.target.value
            };
        }

        setDescription(value);
    };

    const handleChoiceEdit = (e, index) => {
        e.persist();

        let listOfChoices = [...choices];
        if (isDefaultLanguage) {
            listOfChoices[index] = {
                "value": listOfChoices[index].value,
                "text": { "de": e.target.value, "en": listOfChoices[index].text.en }
            };
        } else {
            listOfChoices[index] = {
                "value": listOfChoices[index].value,
                "text": { "de": listOfChoices[index].text.de, "en": e.target.value }
            };
        }

        setChoices(listOfChoices);
    };

    const addChoice = (e) => {
        e.preventDefault();
        let tempChoices = [...choices];

        tempChoices.push({
            value: (choices.length + 1).toString(), text: {
                de: "de",
                en: "en"
            }
        });

        setChoices(tempChoices);
    };

    // init arrow rotation
    const handleFold = () => {
        const arrow = document.getElementById(arrowId.toString());
        if (isFolded) {
            arrow.style.transform = 'rotate(90deg)'
        } else {
            arrow.style.transform = 'rotate(-90deg)'
        }

        setIsFolded(!isFolded);
    };

    const toJSON = () => {
        return {
            "type": type,
            "name": name,
            "visible": isVisible,
            "isExam": isExam,
            "title": title,
            "description": description,
            "choices": choices,
            "correctAnswer": correctAnswer,
            "choicesOrder": "random"
        }
    };

    // Update the component
    useEffect(() => {
        update(toJSON(), index);
    }, [title, description, isVisible, isExam, choices, correctAnswer]);

    const choicesComponent = (isFolded) ? (
        <div className={"expandable"}>
            <div className={"col-12 titles"}>
                <label>{descriptionString}</label>
                <input className={"col-12"} type={"text"}
                    value={isDefaultLanguage ? description.de : description.en} onChange={handleDescription} />
            </div>
            <form className={"col-12 titles"}>
                <span>{decisionString}</span>
                {
                    choices.map((item, i) => {
                        return <div key={i} className={"choices"}>
                            <input data-tip="Richtige Antwort" type={"radio"} className={"col-1"}
                                checked={i === correctAnswerIndex}
                                onChange={() => handleCorrectAnswerChange(i, item)} />
                            <ReactTooltip />
                            <input type={"text"} className={"col-10"}
                                value={isDefaultLanguage ? item.text.de : item.text.en} key={i}
                                onChange={(event) => {
                                    handleChoiceEdit(event, i);
                                }} />
                            <button data-tip="Antwort löschen" className={"col-1"} onClick={(e) => {
                                e.preventDefault();
                                let tempChoices = [...choices];
                                // Delete answer
                                tempChoices.splice(i, 1);
                                setChoices(tempChoices);
                            }}>
                                <img src={addIcon} alt={"add"} />
                            </button>
                            <ReactTooltip />
                        </div>
                    })
                }

                <button data-tip="Auswahlmöglichkeit hinzufügen" onClick={addChoice}
                    className={"btn btn-transparent add-btn"}>
                    <img src={addIcon} alt={"add"} />
                </button>
                <ReactTooltip />
            </form>
        </div>
    ) : null;

    return (
        <div style={{ opacity: opacity }}>
            <div className={"question-row"} onClick={handleFold}>
                <div className={"col-3"}>
                    {index}.
                </div>
                <div className={"col-6 question-type"}>
                    <div className={"text-center"}>
                        <img src={radioIcon} alt={"radio"} />
                        <span>Einzelauswahl</span>
                    </div>
                </div>
                <div className={"col-3"}>
                    <img
                        data-tip={!isFolded ? "Antwortmöglichkeiten ausklappen" : "Antwortmöglichkeiten einklappen"}
                        className={"arrow"} id={arrowId} src={arrow} alt={"arrow"} />
                    <ReactTooltip />
                </div>
            </div>
            <div className={"editable-question"}>
                <div className={"radiogroup row"}>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isVisible"} checked={isVisible} onChange={handleIsVisible} />
                        <label htmlFor={"isVisible"}>{visibleString}</label>
                    </div>

                    <div className={"col-3 visible"}>
                        <input type={"checkbox"} id={"isExam"} checked={isExam} onChange={handleIsExam} />
                        <label htmlFor={"isExam"}>{examString}</label>
                    </div>

                    <div className={"col-3 language-switch"}>
                        <Switch
                            id={"language"}
                            checked={isDefaultLanguage}
                            onChange={handleLanguage}
                            offColor={"#3f3d3d"}
                            onColor={"#3f3d3d"}
                            uncheckedIcon={<img src={enFlag} alt="en" />}
                            checkedIcon={<img src={deFlag} alt="de" />}
                            width={70}
                            height={25}
                        />
                    </div>
                    <div className={"col-3 del-btn"}>
                        <button data-tip={"Frage löschen"} onClick={() => {
                            // This is a quick solution: might actually spend the time to make a decent confirmation component
                            var r = window.confirm("Bist du sicher das du diese Frage löschen möchtest?");
                            if (r === true) {
                                delQuestion(index - 1)
                            }
                        }}>
                            <img src={delIcon} alt={"add"} />
                        </button>
                        <ReactTooltip />
                    </div>
                    <div className={"col-12 titles"}>
                        <label>{titleString}</label>
                        <input className={"col-12"} type={"text"} value={isDefaultLanguage ? title.de : title.en}
                            onChange={handleTitle} />
                    </div>
                    {choicesComponent}
                </div>
            </div>
        </div>
    )
};

export default RadioGroup;