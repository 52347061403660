const getChoices = (question) => {
    let choices = [];
    question.choices.forEach((e) => {
        choices.push(e.text);
    });

    return choices;
};

/**
 * generate JSON based on the question type
 *
 * @param {string} question
 * @param {string} data
 * @return {string} JSON obj
 */
export const generateQuestionResult = (question, data) => {
    let json = {};

    switch (question.getType()) {
        case "radiogroup":
            json["type"] = "radiogroup";
            json["name"] = question.name;
            json["choices"] = getChoices(question);
            json["selectedChoices"] = question.choices.find(obj => obj.value == data)?.text
            break;
        case "checkbox":
            json["type"] = "checkbox";
            json["name"] = question.name;
            json["choices"] = getChoices(question);
            json["selectedChoices"] = data;
            break;
        case "boolean":
            json["type"] = "boolean";
            json["name"] = question.name;
            json["choice"] = question.title;
            json["selectedChoice"] = data;
            break;
        default:
            json["type"] = question.getType();
            json["name"] = "";
            break;
    }

    return json
};

export const formatLabel = (labelString, maxwidth) => {
    let sections = [];
    let words = labelString.split(" ");
    let temp = "";

    words.forEach((item, index) => {
        if (item.length >= maxwidth && index == (words.length - 1)) { return sections.push(item) }
        if (index == (words.length - 1)) { return sections.push(temp + ' ' + item) }

        temp = temp + ' ' + item

        if (temp.length > maxwidth) {
            sections.push(temp)
            temp = ""
        }
    })

    return sections.map(str => str.trim())
}

export const calculateMaxWidthForLabel = question => {
    // 80 is the approximate number of characters the x axis can display on a single line in a chart
    return 80 / (question?.answersCount?.length > 0 ? question?.answersCount?.length : 1)
}

