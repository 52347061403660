import React from 'react';
import RadioGroupChart from "./Charts/RadioGroupChart";
import BooleanChart from "./Charts/BooleanChart";
import CheckBoxChart from "./Charts/CheckboxChart";


const Analytics = ({ mainColor, secondaryColor, results }) => {
    return (
        <div className={"row"}>{
            results.map((question, i) => {
                switch (question.type) {
                    case "radiogroup":
                        return <div className={"col-12"} key={i}>
                            <RadioGroupChart
                                key={question.name}
                                index={i}
                                question={question}
                                mainColor={mainColor}
                                secondaryColor={secondaryColor} />
                        </div>;
                    case "boolean":
                        return <div className={"col-12"} key={i}>
                            <BooleanChart
                                key={question.name}
                                index={i}
                                question={question}
                                mainColor={mainColor}
                                secondaryColor={secondaryColor} />
                        </div>;
                    case "checkbox":
                        return <div className={"col-12"} key={i}>
                            <CheckBoxChart
                                key={question.name}
                                index={i}
                                question={question}
                                mainColor={mainColor}
                                secondaryColor={secondaryColor} />
                        </div>;
                    default:
                        return <h4 key={question.name}>This question Type is not supported</h4>;
                }
            })}
        </div>
    );
};

export default Analytics;
