import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LanguageSelection from "./LanguageSelection";
import { checkPrivateKey, getExamDataByKey, createExam } from "../utils/api";
import { setSessionStorage } from "../utils/sessionStorage";
import cogoToast from "cogo-toast";

const ExamSelection = ({ trainingName, type, isExam }) => {
  const [name, setName] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [language, setLanguage] = useState("de");
  let history = useHistory();

  const languageSetter = lang => {
    setLanguage(lang);
  };

  const handleKey = e => {
    e.preventDefault();
    setPrivateKey(e.target.value);
  };

  const handleName = e => {
    e.preventDefault();
    setName(e.target.value);
  };

  const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear() - 2000;

    return year * 10000 + (month + 1) * 100 + day;
  }

  const onSubmitHandler = e => {
    e.preventDefault();
    let validity = null;
    let expired = false;
    let basicQuestions;
    let eventQuestions;
    let rollsQuestions;
    let poevmQuestions;
    let okrBasicQuestions;
    let okrPraxisQuestions;
    let okrManagementQuestions;
    let agile_coaching_leadership_Questions
    let agile_leadership_1_Questions
    let agile_leadership_2_Questions
    let agile_leadership_3_Questions
    let kanban_basics_Questions
    let kanban_ibqmi_Questions
    let agile_exam_Questions
    let isKeyUsed = false;

    getExamDataByKey(privateKey)
      .then(res => {
        const { basics, events, rolls, po_evm, okr_basics, okr_management, okr_praxis,
          agile_coaching_leadership, agile_leadership_1, agile_leadership_2, agile_leadership_3,
          kanban_basics, kanban_ibqmi, agile_exam } = res[0].questionNumber

        basicQuestions = basics
        eventQuestions = events
        rollsQuestions = rolls
        poevmQuestions = po_evm
        okrBasicQuestions = okr_basics
        okrManagementQuestions = okr_management
        okrPraxisQuestions = okr_praxis
        agile_coaching_leadership_Questions = agile_coaching_leadership
        agile_leadership_1_Questions = agile_leadership_1
        agile_leadership_2_Questions = agile_leadership_2
        agile_leadership_3_Questions = agile_leadership_3
        kanban_basics_Questions = kanban_basics
        kanban_ibqmi_Questions = kanban_ibqmi
        agile_exam_Questions = agile_exam

        validity = res[0].validity;

        const descriptionData = res[0].description;
        const examDuration = res[0].examDuration * 60;
        const usedKeys = res[0].usedKeys;

        // Sorted expirationDate
        const tempArray = validity.split(".");
        // bcd-ish date
        const sortedExpirationDate =
          parseInt(tempArray[2]) * 10000 +
          parseInt(tempArray[1]) * 100 +
          parseInt(tempArray[0]);

        // Sorted todaysDate
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth();
        const year = today.getFullYear() - 2000;

        const sortedTodaysDate = getDate()
        const str = sortedTodaysDate.toString()
        const dateToday =
          str.substring(4, 6) +
          "." +
          str.substring(2, 4) +
          "." +
          str.substring(0, 2)

        if (sortedTodaysDate > sortedExpirationDate) {
          expired = true;
          cogoToast.error("Teilnahmeschlüssel abgelaufen!");
        }

        if (usedKeys && usedKeys.includes(privateKey)) {
          isKeyUsed = true;
          cogoToast.error("Teilnahmeschlüssel bereits verwendet!");
        }

        checkPrivateKey(privateKey).then(res => {
          if (
            res === "success" &&
            expired === false &&
            isKeyUsed === false
          ) {
            createExam(
              basicQuestions,
              eventQuestions,
              rollsQuestions,
              poevmQuestions,
              okrBasicQuestions,
              okrPraxisQuestions,
              okrManagementQuestions,
              agile_coaching_leadership_Questions,
              agile_leadership_1_Questions,
              agile_leadership_2_Questions,
              agile_leadership_3_Questions,
              kanban_basics_Questions,
              kanban_ibqmi_Questions,
              agile_exam_Questions
            ).then(res => {

              setSessionStorage({ privateKey, language, trainingName, name, dateToday, descriptionData })

              res.maxTimeToFinish = examDuration;
              history.push({
                pathname: `/exam/${privateKey}`,
                state: {
                  username: name,
                  name: trainingName,
                  type: type,
                  language: language,
                  key: privateKey,
                  description: descriptionData,
                  date: dateToday,
                  survey: res,
                },
              });
            });
          }
        });
      })
      .catch(() => {
        cogoToast.error("Teilnahmeschlüssel ungültig!");
      });
  };

  const inputFields = isExam ? (
    <div className="col-12">
      <input
        type="text"
        value={name}
        onChange={handleName}
        placeholder="Name"
      />
      <input
        className="key"
        type="text"
        value={privateKey}
        onChange={handleKey}
        placeholder="Teilnahmeschlüssel"
      />
    </div>
  ) : null;

  const checkKey = (
    <button
      disabled={privateKey.length === 0 || name.length === 0}
      type="submit"
      className="btn btn-transparent"
      onClick={onSubmitHandler}
    >
      Teilnehmen
    </button>
  );

  return (
    <div className="training-selection">
      <h4> {trainingName} </h4>
      <form className="row">
        {inputFields}
        <LanguageSelection langSetter={languageSetter} />
        <br />
        <p className="participate col-8">{checkKey}</p>
      </form>
    </div>
  );
};

export default ExamSelection;
