import React, { useContext, useEffect, useState } from 'react';
import PreviousExam from "./PreviousExam"
import { deleteExamObject, getPreviousExams } from "../../utils/api";
import { TrainingsContext } from '../../context';
import cogoToast from 'cogo-toast';

// component on the right of the admin page, with list of previous trainings and button to results
const PreviousExams = () => {
    const { data: { exams }, setExams } = useContext(TrainingsContext)

    const handleExamDelete = id => {
        let r = window.confirm(
            "Bist du sicher das du diese Prüfung löschen möchtest?"
        )
        if (r) {
            deleteExamObject(id)
                .then(res => {
                    cogoToast.success("Prüfung wurde gelöscht")
                    setExams({ validExams: exams.validExams.filter(key => key._id !== id), expiredExams: exams.expiredExams.filter(key => key._id !== id) })
                })
                .catch(err => {
                    cogoToast.error("Ein Fehler ist aufgetreten")
                })
        }
    }

    return (
        <>
            <div id={"valid-previous-trainings"} className={"interaction-container previous-trainings col-12 col-sm-12"}>
                <br />
                <h1 className={"text-center col-12"}>Gültige Prüfungen</h1>
                {
                    exams?.validExams?.map((item, i) => {
                        item.training = "exam";
                        return <PreviousExam key={item._id} item={item} handleExamDelete={handleExamDelete} />
                    })}
            </div>
            <div id={"previous-trainings"} className={"interaction-container previous-trainings col-12 col-sm-12"}>
                <br />
                <h1 className={"text-center col-12"}>Abgelaufene Prüfungen</h1>
                {
                    exams?.expiredExams?.map((item, i) => {
                        item.training = "exam";
                        return <PreviousExam key={item._id} item={item} handleExamDelete={handleExamDelete} />
                    })}
            </div>
        </>
    )
};

export default PreviousExams;