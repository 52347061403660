import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Title from "../Misc/Title";
import loginImg from "../../images/icon-log-in.svg";
import {login} from "../../utils/api";
import cogoToast from "cogo-toast";

const Login = () => {
    let history = useHistory();
    let location = useLocation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);

    const handleInput = (e) => {
        const inputFieldName = e.target.name;

        if (inputFieldName === "username") {
            setUsername(e.target.value);
        } else if (inputFieldName === "password") {
            setPassword(e.target.value);
        }

        if (e.target.value.length > 0) {
            setBtnDisabled(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (username.length > 0 && password.length > 0) {
            login(username, password).then(() => {
                const {from} = location.state || {
                    from: {
                        pathname: "/admin"
                    }
                };
                history.push(from);
            }).catch(err => {
                if (err.message === "false_credentials") {
                    cogoToast.error("Fehlerhafte Zugangsdaten!")
                } else {
                    cogoToast.error("Es ist ein Fehler aufgetreten!")
                }
            });
        }
    };

    return (
        <div>
            <Title title={"ADMINISTRATION"}/>
            <br/>
            <div className={"login-form text-center"}>
                <h2 className={"col-12"}>LOGIN</h2>
                <form className={"col-12"} onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        placeholder={"Nutzername"}
                        onChange={handleInput}
                    />
                    <br/>
                    <input
                        type="password"
                        name="password"
                        placeholder={"Passwort"}
                        value={password}
                        onChange={handleInput}
                    />
                    <br/>
                    <button
                        disabled={btnDisabled}
                        className={"btn btn-transparent"}
                    >
                        Einloggen
                        <img src={loginImg} alt={"login"}/>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
