import React, { useEffect } from 'react';
import Chart from 'chart.js'
import { calculateMaxWidthForLabel, formatLabel } from '../../../../utils/result-utils';

const RadioGroupChart = ({ question, index, mainColor, secondaryColor }) => {
    const getBackgroundColors = () => {
        let colorList = [];

        question.choices.forEach((answer) => {
            if (question.correctAnswer === answer) {
                colorList.push(secondaryColor);
            } else {
                colorList.push(mainColor);
            }
        });

        return colorList;
    };

    const getBorderColors = () => {
        let colorList = [];

        question.choices.forEach(() => {
            colorList.push(mainColor);
        });

        return colorList;
    };

    useEffect(() => {
        calculateMaxWidthForLabel(question)
        new Chart(question.name, {
            type: 'bar',
            data: {
                labels: question.choices,
                datasets: [{
                    label: 'Anzahl der Antworten: ',
                    data: question.answersCount,
                    backgroundColor: getBackgroundColors(),
                    borderColor: getBorderColors(),
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        display: 'true',
                        scaleLabel: {
                            display: true,
                            labelString: 'Anzahl der Antworten'
                        },
                        ticks: {
                            fontColor: 'white',
                            beginAtZero: true,
                            precision: 0
                        }
                    }],
                    xAxes: [{
                        display: 'true',
                        scaleLabel: {
                            display: true,
                        },
                        ticks: {
                            callback: (value, index, ticks) => {
                                return formatLabel(value, calculateMaxWidthForLabel(question))
                            },
                            fontColor: 'white',
                            beginAtZero: true,
                            precision: 0,
                        }
                    }]
                }
            }
        });
    });

    return (
        <div className={"chartbox col-md-12"}>
            <span>{index + 1}. {question.title}</span>
            <div>
                <canvas id={question.name} width="200" height="200" />
            </div>
        </div>
    );
};

export default RadioGroupChart;
