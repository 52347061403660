import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ScrollUpComponent from "../../Misc/ScrollUpButton";
import ScrollUpButton from "react-scroll-up-button";
import Title from "../../Misc/Title";
import {
  getExamData,
  getExamResultData,
  getExamResults,
} from "../../../utils/api";
import cogoToast from "cogo-toast";
import Results from "./Results";

const queryString = require("query-string");

const ExamResults = ({ ...props }) => {
  const [examKeys, setExamKeys] = useState({
    candidates: null,
    date: null,
    description: null,
    examDuration: null,
    keys: [],
    questionNumber: {},
    training: null,
    validity: null,
    _id: null,
  });
  const key = queryString.parse(props.location.search).key;
  const [examResultData, setExamResultData] = useState([]);
  const [examResults, setExamResults] = useState([])
  const [averageExamResults, setAverageExamResults] = useState([])
  let history = useHistory();

  useEffect(() => {
    getExamData(key)
      .then(res => {
        res.map(data => {
          setExamKeys(data);
        });
      })
      .catch((err) => {
        cogoToast.error(err);
      });
  }, []);

  useEffect(() => {
    getExamResultData(key)
      .then(res => {
        setExamResultData(res)

      })
      .catch((err) => {
        cogoToast.error(err);
      });

    getExamResults(key)
      .then(res => {
        setExamResults(res.resultList.results)
        setAverageExamResults(res.averageResultsArray)
      }).catch((err) => {
        cogoToast.warn('this exam has no available results yet');
      });
  }, []);

  const loadResults = () => {
    history.push({
      pathname: `/admin/result`,
      state: {
        key: key,
        results: examResults,
        averageResults: averageExamResults,
        origin: 'exam'
      }
    })
  }

  return (
    <div className={"charts"}>
      <ScrollUpButton
        ContainerClassName="scroll-up-container"
        TransitionClassName="scroll-up-transition"
      >
        <ScrollUpComponent />
      </ScrollUpButton>
      <Title title="Infos zur Prüfung" />
      <h4>Bezeichnung der Prüfung: {examKeys.description}</h4>
      <h4>Anzahl der Teilnehmer: {examKeys.candidates}</h4>
      <h4>Prüfungsdauer: {examKeys.examDuration} Minuten</h4>
      <h4>Gültigkeitszeitraum: {examKeys.validity}</h4>
      <h4>Anzahl der Fragen je Übung: </h4>
      <h6>Basics: {examKeys.questionNumber.basics} Fragen</h6>
      <h6>Events: {examKeys.questionNumber.events} Fragen</h6>
      <h6>Rolls: {examKeys.questionNumber.rolls} Fragen</h6>
      <h6>PO_EVM: {examKeys.questionNumber.po_evm} Fragen</h6>
      <h6>OKR Basics: {examKeys.questionNumber.okr_basics} Fragen</h6>
      <h6>OKR Praxis: {examKeys.questionNumber.okr_praxis} Fragen</h6>
      <h6>OKR Management: {examKeys.questionNumber.okr_management} Fragen</h6>
      <h6>Agile Coaching - Agile Leadership: {examKeys.questionNumber.agile_coaching_leadership} Fragen</h6>
      <h6>Agile Leadership I: {examKeys.questionNumber.agile_leadership_1} Fragen</h6>
      <h6>Agile Leadership II: {examKeys.questionNumber.agile_leadership_2} Fragen</h6>
      <h6>Agile Leadership III: {examKeys.questionNumber.agile_leadership_3} Fragen</h6>
      <h6>Kanban IBQMI: {examKeys.questionNumber.kanban_ibqmi} Fragen</h6>
      <h6>Kanban Basics: {examKeys.questionNumber.kanban_basics} Fragen</h6>
      <br />
      <h4>Schlüssel:</h4>
      {examKeys.keys.map((data) => {
        return <h6 key={data} >{data}</h6>;
      })}
      <br />

      <Title title="Ergebnisse" />
      <button className={`btn btn-transparent mb-4 ${examResults.length == 0 ? 'cancel' : ''}`}
        disabled={examResults.length == 0 ? true : false}
        onClick={loadResults} >
        Detaillierte Ergebnisse ansehen
      </button>
      {examResultData.map((ausgabe) => {
        return (
          <div key={ausgabe.key}>
            <h4>Prüfungscode: {ausgabe.key}</h4>
            <h4>Name der Prüfung: {ausgabe.description}</h4>
            <h4>Name des Prüflings: {ausgabe.name}</h4>
            <h4>Prozentualer Erreichungsgrad: {ausgabe.percentage}%</h4>
            <h4>Prüfung absolviert am: {ausgabe.date}</h4>
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default ExamResults;
