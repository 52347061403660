import React, { useState, useEffect, useContext } from "react";
import addImg from "./../../images/icon-add-element.svg";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import "./DatePicker.css";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { uploadNewExam, getTraining } from "../../utils/api";
import { TrainingsContext } from "../../context";

const NewExam = (props) => {
  const [description, setDescription] = useState("");
  const [candidates, setCandidates] = useState();
  const [examDuration, setExamDuration] = useState();
  // TODO: consider using an array as a data structure instead of an object
  const [exams, setExams] = useState({})
  const [expirationDateOfExam, setExpirationDateOfExam] = useState(new Date());
  const [loaded, setLoaded] = useState(false)
  const [types, setTypes] = useState([])

  const { data: { trainingLabels } } = useContext(TrainingsContext)

  useEffect(() => {
    if (trainingLabels?.length > 0) {
      const obj = {}
      trainingLabels.forEach(label => {
        const { type, title } = label
        obj[type] = {
          maxExamQuestions: 0,
          requestedExamQuestions: null,
          errorText: title
        }
      })
      setExams(obj)
      setLoaded(true)
    }
  }, [trainingLabels])

  useEffect(() => {
    if (loaded) {
      for (const category in exams) {
        getTraining(`${category}`).then(result => {
          accumulateCount(result, `${category}`)
        })
      }
    }
  }, [loaded]);


  const accumulateCount = (result, training) => {
    const json = result?.pages[1]?.elements;
    const keys = Object.keys(json);
    keys.forEach((key) => {
      if (json[key].isExam) {
        setExams(exams => ({
          ...exams,
          [training]: {
            ...exams[training],
            maxExamQuestions: exams[training].maxExamQuestions + 1
          }
        }))
      }
    }, training)
  }

  registerLocale('de', de)

  const handleDescription = e => {

    e.preventDefault();
    setDescription(e.target.value);
  };

  const handleCandidates = e => {
    e.preventDefault();
    setCandidates(e.target.value);
  };

  const handleExamDuration = e => {
    e.preventDefault();
    setExamDuration(e.target.value);
  };

  const questionHandler = (e, category) => {
    e.persist();
    if (e.target.value <= exams[category].maxExamQuestions) {
      setExams(exams => ({
        ...exams,
        [category]: {
          ...exams[category],
          requestedExamQuestions: e.target.value
        }
      }));
    } else {
      cogoToast.error(`Nicht genügend ${exams[category].errorText}-Fragen als Exam markiert!`);
      e.target.value = ''
    }
  }

  const getQuestionNumbers = () => {
    const questionNumber = {};
    for (const category in exams) {
      questionNumber[`${category}`] = `${exams[category].requestedExamQuestions}`
    }
    return questionNumber;
  }

  const generateArrayOfKeys = candidates => {
    const keys = [];
    const countOfCandidates = parseInt(candidates);
    for (let i = 1; i <= countOfCandidates; i++) {
      const generatedID = uuidv4();
      keys.push(generatedID.substring(0, 8));
    }
    return keys
  };

  // iterate through the questions allocation's object and return the type of the questionpool
  const classifyExamToTraining = questionsArray => {
    for (const questionsType in questionsArray) {
      if (questionsArray[questionsType] && questionsArray[questionsType] > 0) {
        return questionsType
      }
    }
  }

  // check if the exam has questions from different question pools 
  const checkExamQuestionsValidity = questionsArray => {
    let questionPoolsNumber = 0
    for (const questionsType in questionsArray) {
      if (questionsArray[questionsType] && questionsArray[questionsType] > 0) {
        questionPoolsNumber++
      }
    }
    return questionPoolsNumber > 1
  }

  const handleAddExamType = e => {
    if (e.target.checked) {
      setTypes([...types, e.target.value])
    } else {
      setTypes(types.filter(type => type !== e.target.value))
    }
  }

  const resetFormInputs = () => {
    const examsArray = Object.entries(exams)
    examsArray.forEach(exam => {
      const [, questionAllocation] = exam
      questionAllocation.requestedExamQuestions = ''
    })
    setExams(Object.fromEntries(examsArray))
    setDescription('')
    setCandidates('')
    setExamDuration('')
    setExpirationDateOfExam(new Date())
  }

  const newExam = () => {

    const keys = generateArrayOfKeys(candidates);
    const questionNumber = getQuestionNumbers();
    const formatedExpirationDate = dayjs(expirationDateOfExam).format("DD.MM.YY");

    /* if (checkExamQuestionsValidity(questionNumber)) {
      cogoToast.warn('Die Erstellung von Prüfungen aus mehreren Fragenpools ist noch nicht möglich')
      return
    } */
    const type = classifyExamToTraining(questionNumber)
    const hasMultipleTypes = checkExamQuestionsValidity(questionNumber)

    uploadNewExam(
      description,
      candidates,
      keys,
      questionNumber,
      examDuration,
      formatedExpirationDate,
      type,
      hasMultipleTypes,
    )
      .then(() => {
        cogoToast.success("Neue Prüfung wurde angelegt!");
        resetFormInputs()
        document
          .getElementById("valid-previous-trainings")
          .dispatchEvent(new Event("newKey"));
      })
      .catch((err) => {
        cogoToast.error(
          "Beim Hochladen der Prüfung ist ein Fehler aufgetreten!"
        );
        console.log(err);
      });

    /* setTimeout(() => {
      window.location.reload();
    }, 300); */
  };


  return (
    <form id='new-exam-form'>
      <div className={"interaction-container new-training col-12"}>
        <h1>Neue Prüfung anlegen</h1>
        <hr />
        <div className={"col-12"}>
          <h3>Bezeichnung der Schulung</h3>
          <input
            className={"key"}
            type={"text"}
            value={description}
            onChange={handleDescription}
            placeholder={"Bezeichnung der Schulung"}
          />
        </div>
        <br />
        <div className={"col-12"}>
          <h3>Anzahl Teilnehmer</h3>
          <input
            className={"key"}
            type={"number"}
            min="0"
            value={candidates}
            onChange={handleCandidates}
            placeholder={"Anzahl Teilnehmer"}
          />
        </div>
        <br />
        <div className={"col-12"}>
          <h3>Anzahl Fragen</h3>

          {trainingLabels?.map(label => {
            return (
              <div className="row" key={label.type}>
                {/* <input type="checkbox" value={label.type} className="mt-4" onChange={handleAddExamType} /> */}
                <h4 className="col-sm-6 col-md-5 col-lg-4 col-xl-3">{label.title}:</h4>
                <input
                  className="col-6 key"
                  type={"number"}
                  min="0"
                  value={exams[label.type]?.requestedExamQuestions ? [label.type]?.requestedExamQuestions : ''}
                  onChange={e => questionHandler(e, label.type)}
                  placeholder={`Anzahl Fragen ${label.title}`}
                />
              </div>
            )
          })}
        </div>
        <br />
        <div className={"col-12"}>
          <h3>Prüfungsdauer (in Minuten)</h3>
          <input
            className={"key"}
            type={"number"}
            min="0"
            value={examDuration}
            onChange={handleExamDuration}
            placeholder={"Prüfungsdauer"}
          />
        </div>
        <br />
        <div className={"col-12"}>
          <h3>Code gültig bis:</h3>
          <DatePicker
            className={"text-center"}
            dateFormat="dd.MM.yy"
            closeOnScroll={true}
            selected={expirationDateOfExam}
            onChange={expirationDateOfExam => setExpirationDateOfExam(expirationDateOfExam)}
            locale="de"
          />
        </div>
        <button
          id={"new-key"}
          className={"btn btn-transparent btn-prüfung"}
          onClick={newExam}
          disabled={description.length === 0}
        >
          Prüfung anlegen
        <img src={addImg} alt={"add"} />
        </button>
      </div>
    </form>
  );
};

export default NewExam;
