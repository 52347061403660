import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as Survey from 'survey-react';
import { getTraining, sendResult } from "../../utils/api";
import { generateQuestionResult } from "../../utils/result-utils";
import { filterQuestionTypes, loadState, setTrainingTranslations } from "../../utils/training-utils";
import Title from "../Misc/Title";
import QuestionSelection from "./QuestionSelection";
import TrainingComplete from "./TrainingComplete";

// TODO update survey-description based on language
const Training = (...props) => {
    const [training, setTraining] = useState(null);
    const [unprocessedTrainingObject, setUnprocessedTrainingObject] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [correctPercentage, setCorrectPercentage] = useState(0);
    const [isCompleted, testIsCompleted] = useState(false); // is user done?
    const [hasBegun, setHasBegun] = useState(false);
    const [number, setNumber] = useState(0);

    const language = sessionStorage.getItem("language")
    const key = sessionStorage.getItem("key")
    const pageTitle = sessionStorage.getItem("pageTitle")

    const collection = atob(props[0].match.params.type)


    useEffect(() => {
        if (!loaded) {
            getTraining(collection).then(res => {
                // TODO: Refactor and comment as module function
                if (language !== 'de') {
                    res.pages[0].elements[0].html =
                        "<div class='text-center'><span class='survey-description'>" +
                        res.introductionEnglish +
                        "</span><br><br><span id='time-to-finish'/></div>"
                }
                const filteredQuestions = filterQuestionTypes(res)
                setUnprocessedTrainingObject(res)
                setTraining(new Survey.Model(JSON.stringify(filteredQuestions)))
                setLoaded(true);
            });
        }
    }, []);

    useEffect(() => {
        if (training) {
            training.locale = language

            setTrainingTranslations(training, language);

            // set time left on training, this is done to avoid trivial cheating in a naive way.
            const timeLeft = sessionStorage.getItem("timeLeft");
            if (timeLeft) {
                training.maxTimeToFinish = timeLeft;
            }

            // Write the time into the start page
            const timeToFinishElement = document.getElementById('time-to-finish');
            let timeToFinish = 0;

            // clamp timevalue
            if (timeToFinish < 0) {
                timeToFinish = 0;
            }

            // render how much time is left to finish training
            if (timeToFinishElement) {
                // its possible that there is no time set
                if (training.maxTimeToFinish)
                    timeToFinish = Math.floor(training.maxTimeToFinish / 60);

                if (language === 'de' && timeToFinish > 0) {
                    timeToFinishElement.innerHTML = `Du hast ${timeToFinish} Minuten Zeit.`;
                } else if (timeToFinish > 0) {
                    timeToFinishElement.innerHTML = `You have ${timeToFinish} minutes to finish.`;
                }
            }

            // loading json questions from session storage
            loadState(training);
        }
    }, [training]);

    // reading and loading Page title
    const saveState = (survey) => {
        let res = {
            // TODO: fix feature of reloading trainings
            // currentPageNo: survey.currentPageNo,
            data: survey.data,
        };

        let response = {};
        for (let key in res.data) {
            if (res.data.hasOwnProperty(key)) {
                const question = survey.getQuestionByName(key);

                response[question.title] = question.value;
            }
        }

        // Save input into session
        window
            .sessionStorage
            .setItem("training", JSON.stringify(res));
    };

    /**
     * method is triggered by onComplete SurveyJS event
     * TODO make sure that empty arrays get send to results route if no selectedChoices
     */
    const onTestComplete = (training) => {
        saveState(training);

        // Stoping the timer
        training.stopTimer();
        const questionCount = training.getQuizQuestionCount();
        const correctedAnswers = training.getCorrectedAnswerCount();

        setCorrectPercentage((correctedAnswers / questionCount) * 100);

        // Training is done
        testIsCompleted(true);

        let result = { questions: [] };

        [training.data].map((item) => {
            training.getAllQuestions().forEach((question) => {
                if (!item[question.name]) {
                    item[question.name] = "";
                }

                if (question.name !== 'entry' && question.visible) {
                    result.questions.push(generateQuestionResult(question, training.data[question.name]));
                }
            });
            return item;
        });

        result["points"] = correctedAnswers;
        result["key"] = key
        result["collection"] = collection
        result["percentage"] = ((correctedAnswers / questionCount) * 100).toFixed(0);

        sessionStorage.setItem("answers", JSON.stringify(result));

        sendResult(result);
    };

    const onTestStarted = (options) => {
        setHasBegun(true);
        options.startTimer();
    };

    /**
     * event is triggered every second if the method startTimer has been called.
     * @return the time in seconds end-user spends on the survey
     */
    const onTestTimer = (options) => {
        let root = document.getElementById("timer-progress");
        sessionStorage.setItem("timeLeft", options.maxTimeToFinish - options.timeSpent);
        let timeLeftPercentage = ((options.maxTimeToFinish - options.timeSpent) / options.maxTimeToFinish) * 100;
        root.style.setProperty('--timer-progress-width', timeLeftPercentage + "%");
    };

    const onPageChangeNumber = (training) => {
        let frage = training.currentPageNo;
        setNumber(++frage);
    }

    const trainingStarted = (hasBegun) ? (
        <div className="training-questions-counter"><h4>Frage {number} von {training.getQuizQuestionCount()}</h4></div>
    ) : null;

    const timerProgress = (
        <div className="training-timer-container">
            <div id="timer-progress" className="timer-progress" />
        </div>
    );

    const testComponent = (!isCompleted && training !== null) ? (
        <div id="surveyElement">
            {trainingStarted}
            {timerProgress}
            <Survey.Survey
                model={training}
                showTitle={false}
                showPageNumbers={false}
                showTimerPanel={"none"}
                showProgressBar={"none"}
                onComplete={onTestComplete}
                onStarted={onTestStarted}
                onTimer={onTestTimer}
                onCurrentPageChanged={onPageChangeNumber}
                questionsOnPageMode={"questionPerPage"}
            />
        </div>
    ) : null;

    // Result Page
    const onCompleteComponent = (isCompleted) ? (
        <TrainingComplete resultPercentage={correctPercentage} surveyModel={JSON.stringify(unprocessedTrainingObject)} />
    ) : null;

    const questionSelector = (hasBegun && !isCompleted) ? (
        <QuestionSelection surveyModel={training} />
    ) : null;

    const loadingAnim = (!loaded) ? (
        <div className={"loading"}>
            <ReactLoading type={"bubbles"} color={"#61bdaa"} height={300} width={100} />
        </div>
    ) : null;

    return (
        <div className={"training"}>
            <Title title={pageTitle} />
            {loadingAnim}
            {testComponent}
            {onCompleteComponent}
            {questionSelector}
        </div>
    );
};

export default Training;
