import React, { useContext, useEffect, useRef, useState } from "react";
import Title from "../../Misc/Title";
import AddQuestion from "./AddQuestion";
import settingsIcon from "../../../images/settings.svg";
import Settings from "./Settings";
import DragArea from "./DragArea";
import ReactTooltip from "react-tooltip";
import ScrollUpComponent from "../../Misc/ScrollUpButton";
import ScrollUpButton from "react-scroll-up-button";
import cogoToast from "cogo-toast";
import {
  getTraining,
  updateTraining,
  getPreviousExams,
  getTrainingAvailable,
  sendTrainingAvailable,
  deleteTrainingAvailable,
  changeLabel,
  getLabels,
} from "../../../utils/api";
import { getTitleFromType } from "../../../utils/training-utils";
import { TrainingsContext } from "../../../context";
//import { ExamsContext, TrainingsContext } from "../../../context";

const TrainingEditor = ({ ...props }) => {

  const { data: { trainingLabels, activeTrainings, exams }, setTrainingLabels, setActiveTrainings } = useContext(TrainingsContext)

  //const [trainingLabels, setTrainingLabels] = useState()
  const trainingType = props.match.params.type;

  const dragAreaRef = useRef();
  const [maxTimeToFinish, setMaxTimeToFinish] = useState(900);
  const [trainingTitle, setTrainingTitle] = useState({
    de: "SCRUM TRAINING",
    en: "SCRUM TRAINING",
  });
  const [initialTitle, setInitialTitle] = useState(null)
  const [introText, setIntroText] = useState("");
  const [introTextEnglish, setIntroTextEnglish] = useState("")
  const [showSettings, setShowQuestions] = useState(false);
  const [isVisible, setIsVisible] = useState(false)
  const [active, setActive] = useState(null)
  const [questions, setQuestions] = useState([]);
  const [activeExams] = useState([]);
  const [questionNumber] = useState([]);
  const [titleChanged, setTitleChanged] = useState(null)

  useEffect(() => {
    if (trainingLabels?.length > 0) {
      const label = getTitleFromType(trainingLabels, trainingType)
      const obj = { de: label, en: label }
      setTrainingTitle(obj)
      setInitialTitle(obj)
    }
  }, [trainingLabels, trainingType])

  useEffect(() => {
    if (titleChanged) {
      getLabels().then(res => {
        setTrainingLabels(res)
      })
    }
  }, [setTrainingLabels, titleChanged])

  useEffect(() => {
    getTraining(trainingType).then((res) => {
      // setting questions
      let json = res.pages[1].elements;
      let result = [];
      let keys = Object.keys(json);
      keys.forEach((key) => {
        result.push(json[key]);
        if (json[key].isExam) {
          activeExams.push(true);
        }
      });
      setQuestions(result);
      setMaxTimeToFinish(res.maxTimeToFinish);
      setIntroText(res.introduction)
      if (res.introductionEnglish) setIntroTextEnglish(res.introductionEnglish)

    });
  }, [activeExams, trainingType]);

  useEffect(() => {
    if (activeTrainings?.length > 0) {
      for (const active in activeTrainings) {
        if (activeTrainings[active].type == trainingType) {
          setIsVisible(true)
        }
      }
    }
  }, [activeTrainings, trainingType])



  useEffect(() => {
    if (exams?.validExams?.length > 0) {
      const { validExams } = exams
      for (let i = 0; i < validExams.length; i++) {
        questionNumber.push(validExams[i].questionNumber);
      }
    }
  }, [questionNumber, exams]);

  const handleMaxTime = (time) => {
    setMaxTimeToFinish(time);
  };

  const handleIntroText = (text) => {
    setIntroText(text);
  };

  const handleIntroTextEnglish = text => {
    setIntroTextEnglish(text)
  }

  const handleVisibility = visibility => {
    setIsVisible(visibility.e)
    setActive(visibility.active)
  }

  const handleTrainingTitle = (title, isDefaultLanguage) => {
    const val =
      isDefaultLanguage
        ? {
          de: title,
          en: trainingTitle.en,
        }
        : {
          de: trainingTitle.de,
          en: title,
        }

    setTrainingTitle(val);
  };

  const handleModal = () => {
    setShowQuestions(!showSettings);
  };

  const getDefaultQuestionJSON = (type) => {
    dragAreaRef.current.addQuestion(type);
  };

  const deleteQuestionAtIndex = (index) => {
    dragAreaRef.current.deleteQuestion(index);
  };

  const handleTrainingEditorSave = (handleSuccessFunctionAfterAsyncCall) => {

    let boolRight = false
    let markedExamQuestions = 0
    let questionAmounts = dragAreaRef.current.getQuestionsJSON()

    for (let i = 0; i < questionAmounts.length; i++) {
      if (questionAmounts[i].isExam) {
        markedExamQuestions++
      }
    }

    let questionsAllocationProExam = []

    for (let i = 0; i < questionNumber.length; i++) {
      if (questionNumber[i][trainingType] != null && questionNumber[i][trainingType] >= 0) {
        questionsAllocationProExam.push(parseInt(questionNumber[i][trainingType]) || 0)
      }
    }

    let requiredQuestionsNumber = Math.max(...questionsAllocationProExam)
    boolRight = requiredQuestionsNumber <= markedExamQuestions

    let r = window.confirm(
      "Bist du sicher das du den Fragenbogenpool überschreiben möchtest?"
    );
    if (r === true && boolRight === true) {
      updateTraining(trainingType, getTrainingJSON())
        .then((res) => {
          if (active) {
            if (isVisible) {
              sendTrainingAvailable(active)
            } else {
              deleteTrainingAvailable(active)
            }
          }

          if (initialTitle.de != trainingTitle.de) {
            changeLabel(trainingType, trainingTitle.de).then(
              res => {
                const changed = trainingLabels.find(obj => obj.type == trainingType)
                setTitleChanged(changed)
              }).catch(err => {
                console.log(err)
              })
          }

          cogoToast.success(
            "Fragenpool wurde erfolgreich aktualisiert!"
          );
          if (handleSuccessFunctionAfterAsyncCall instanceof Function) {
            handleSuccessFunctionAfterAsyncCall()
          }
        })
        .catch((err) => {
          cogoToast.error(
            "Beim Hochladen ist ein Fehler aufgetreten!"
          );
        });
    } else {
      cogoToast.error(
        `Es bestehen gültige Prüfungen, für die ${requiredQuestionsNumber} Fragen benötigt werden. Es sind aktuell ${markedExamQuestions.length} Fragen gekennzeichnet. Bitte anpassen für eine Aktualisierung`
      );
    }
  }


  const getTrainingJSON = () => {
    return {
      locale: "de",
      type: trainingType,
      title: trainingTitle,
      introduction: introText,
      introductionEnglish: introTextEnglish,
      pages: [
        {
          name: "page1",
          elements: [
            {
              type: "html",
              name: "entry",
              html:
                "<div class='text-center'><span class='survey-description'>" +
                introText +
                "</span><br><br><span id='time-to-finish'/></div>",
            },
          ],
        },
        {
          name: "Seite 1",
          elements: dragAreaRef.current.getQuestionsJSON(),
        },
      ],
      completedHtml: "<div/>",
      showProgressBar: "bottom",
      startSurveyText: "Start",
      firstPageIsStarted: true,
      maxTimeToFinish: maxTimeToFinish,
      showTimerPanel: "top",
      showTimerPanelMode: "survey",
    };

  };

  return (
    <div className={"editor"}>
      <Title title={trainingTitle.de} />
      <ScrollUpButton
        ContainerClassName="scroll-up-container"
        TransitionClassName="scroll-up-transition"
      >
        <ScrollUpComponent />
      </ScrollUpButton>

      <Settings
        open={showSettings}
        closeModal={handleModal}
        introText={introText}
        introTextEnglish={introTextEnglish}
        handleIntroText={handleIntroText}
        handleIntroTextEnglish={handleIntroTextEnglish}
        handleVisibility={handleVisibility}
        visible={isVisible}
        maxTime={maxTimeToFinish}
        handleMaxTime={handleMaxTime}
        title={trainingTitle}
        handleTitle={handleTrainingTitle}
        trainingType={trainingType}
        handleSave={handleTrainingEditorSave}
      />
      <div className={"row creator"}>
        <div className={"col-1"}>
          <div data-tip="Multiple Choice Frage" className={"multi-choice"}>
            <AddQuestion
              defaultQuestion={getDefaultQuestionJSON}
              delQuestion={deleteQuestionAtIndex}
              type={"checkbox"}
            />
          </div>
          <ReactTooltip />
          <div data-tip="Einzelauswahl" className={"single-choice"}>
            <AddQuestion
              defaultQuestion={getDefaultQuestionJSON}
              delQuestion={deleteQuestionAtIndex}
              type={"radiogroup"}
            />
          </div>
          <ReactTooltip />
          <div data-tip="Wahr oder Falsch Frage" className={"bool-choice"}>
            <AddQuestion
              defaultQuestion={getDefaultQuestionJSON}
              delQuestion={deleteQuestionAtIndex}
              type={"boolean"}
            />
          </div>
          <ReactTooltip />
        </div>
        <div className={"col-10"}>
          <DragArea
            ref={dragAreaRef}
            type={trainingType}
            questions={questions}
            setQuestions={setQuestions}
            delQuestion={deleteQuestionAtIndex}
          />
        </div>
        <div onClick={handleModal} className={"col-1 settings"}>
          <img src={settingsIcon} alt={"settings"} />
        </div>
        <div className={"button-row"}>
          <a href={"/#/admin"} className={"btn btn-transparent cancel"}>
            Abbrechen
          </a>
          <button
            className={"btn btn-transparent"}
            onClick={handleTrainingEditorSave}
          >
            Fragenpool aktualisieren
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrainingEditor;
