import React, { useState } from "react";
import { Link } from "react-router-dom";
import LanguageSelection from "./LanguageSelection";
import roles from "./../images/illustrations/scrum-roles.svg";
import events from "./../images/illustrations/scrum-events.svg";
import basics from "./../images/illustrations/scrum-basics.svg";
import po_evm from "./../images/illustrations/scrum-evm.svg";
import okr_basics from "./../images/illustrations/okr-basics.svg";
import okr_praxis from "./../images/illustrations/okr-praxis.svg";
import okr_management from "./../images/illustrations/okr-mgmt.svg";
import agile_coaching_leadership from "./../images/illustrations/ac-leadership.svg";
import agile_leadership_1 from "./../images/illustrations/leadership-1.svg";
import agile_leadership_2 from "./../images/illustrations/leadership-2.svg";
import agile_leadership_3 from "./../images/illustrations/leadership-3.svg";
import kanban_basics from "./../images/illustrations/kanban.svg";
import kanban_ibqmi from "./../images/illustrations/kanban-ibqmi.svg";

const TrainingSelection = ({ trainingName, type }) => {
  const [language, setLanguage] = useState("de");
  const [illustration] = useState(() => {
    switch (type) {
      case "rolls":
        return roles;
      case "basics":
        return basics;
      case "events":
        return events;
      case "po_evm":
        return po_evm;
      case "okr_basics":
        return okr_basics;
      case "okr_praxis":
        return okr_praxis;
      case "okr_management":
        return okr_management;
      case "agile_coaching_leadership":
        return agile_coaching_leadership;
      case "agile_leadership_1":
        return agile_leadership_1;
      case "agile_leadership_2":
        return agile_leadership_2;
      case "agile_leadership_3":
        return agile_leadership_3;
      case "kanban_basics":
        return kanban_basics;
      case "kanban_ibqmi":
        return kanban_ibqmi;
      default:
        return null;
    }
  });

  const languageSetter = lang => {
    setLanguage(lang);
  };

  const setNewTrainingInSessionStorage = () => {
    sessionStorage.setItem("key", type)
    sessionStorage.setItem("language", language)
    sessionStorage.setItem("pageTitle", trainingName)
    sessionStorage.removeItem("training")
  }

  return (
    <div className="training-selection">
      <h4> {trainingName} </h4>
      <img className="img-fluid" src={illustration} alt="illustration" />
      <form className="row">
        <LanguageSelection langSetter={languageSetter} />
        <br />
        <Link
          className="col-8 participate"
          to={{
            pathname: `/training/${btoa(type)}`,
            state: {
              name: trainingName,
              type: type,
              language: language,
              key: type,
            },
          }}
        >
          <button
            onClick={setNewTrainingInSessionStorage}
            type="submit"
            className="btn btn-transparent"
          >
            Teilnehmen
          </button>
        </Link>
      </form>
    </div>
  );
};

export default TrainingSelection;
