import React from 'react';

const Statistics = ({title, result}) => {
    return (
        <div className={"stats col-5 text-center"}>
            <h5>{title}</h5>
            <p>{result}</p>
        </div>
    );
};

export default Statistics;
