import React from "react";
import logo from '../images/t2m-logo.svg'

const Footer = () => {
    return (
        <footer className="footer bg-black">
            <div className="container">
                <div className="col-12"/>

                <div className="copyright text-center">
                    <img src={logo} alt="t2m"/>
                    <br/>
                    <p>
                        <a href="https://time2market.de/impressum" target="_blank"
                           rel="noopener noreferrer"> IMPRESSUM </a>
                        <a href="https://time2market.de/datenschutz" target="_blank"
                           rel="noopener noreferrer"> DATENSCHUTZ </a>
                    </p>
                    <br/>
                    <p>Copyright t2m &copy;  {new Date().getFullYear()} . All Rights Reserved.
                    </p>
                    <p>Version 1.1.2</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
