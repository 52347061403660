import React, { useState, useEffect } from 'react';
import * as Survey from 'survey-react';
import { loadState, setQuestionChoicesOrder, setTrainingTranslations } from "../../utils/training-utils";

const TrainingResults = (surveyJSON) => {
  const [training] = useState(new Survey.Model(setQuestionChoicesOrder(surveyJSON.surveyJSON)))
  // reading and loading Page title and localisation
  training.locale = sessionStorage.getItem("language");
  setTrainingTranslations(Survey, sessionStorage.getItem("language"));

  useEffect(() => {
    document.getElementById("surveyElement").scrollIntoView({ behavior: 'smooth' });
    loadState(training);
  });

  const adjustQuestion = (undefined, options) => {
    const question = options.question;
    const htmlElement = document.getElementById(question.id);

    switch (question.getType()) {
      case "radiogroup":
        const listOfAnswersRadioGroup = htmlElement.getElementsByClassName("sv_q_radiogroup");

        for (let item of listOfAnswersRadioGroup) {
          if (item.children[0].children[0].value === question.correctAnswer) {
            item.style.backgroundColor = '#3c5a54';
            item.style.borderRadius = '4px';
            item.style.paddingTop = '3px'
          }
        }
        break;
      case "checkbox":
        const listOfAnswersCheckbox = htmlElement.getElementsByClassName("sv_q_checkbox");

        for (let item of listOfAnswersCheckbox) {
          if (question.correctAnswer.includes(item.children[0].children[0].value)) {
            item.style.backgroundColor = '#3c5a54';
            item.style.borderRadius = '4px';
            item.style.paddingTop = '3px'
          }
        }
        break;
      case "boolean":
        const listOfAnswersBoolean = htmlElement.getElementsByClassName("sv-boolean__label");

        let foundItem = null;
        for (let item of listOfAnswersBoolean) {
          // In case its german
          if (item.innerText === "Falsch" && question.correctAnswer === "False") {
            foundItem = item;
            break;
          } else if (item.innerText === "Wahr" && question.correctAnswer === "True") {
            foundItem = item;
            break;
          } else if (item.innerText === question.correctAnswer) {
            foundItem = item;
            break;
          }
        }
        if (foundItem) {
          foundItem.style.backgroundColor = '#428468';
          foundItem.style.borderRadius = '4px';
          foundItem.style.padding = '3px';
          foundItem.style.color = 'white';
        }
        break;
      case "comment":
        // there is no actual correct Answer for comments
        break;
      default:
        break;
    }
  };

  return (
    <div className={"training"}>
      <div id="surveyElement">
        <Survey.Survey
          model={training}
          showTitle={false}
          showPageNumbers={false}
          showTimerPanel={"none"}
          showProgressBar={"none"}
          onAfterRenderQuestion={adjustQuestion}
          mode={"display"}
          questionsOnPageMode={"singlePage"}
        />
      </div>
    </div>
  );
};

export default TrainingResults;
