import React, { createContext, useState, useMemo, useEffect } from "react";
import { getLabels, getPreviousExams, getPreviousTrainings, getTrainingAvailable, hasSession } from "./utils/api";
import { filterExpiredExams } from "./utils/context-utils";
import { checkSession, getTitleFromType } from "./utils/training-utils";

const initialData = {
  trainingLabels: [],
  questionPools: [],
  activeTrainings: [],
  previousTrainings: [],
  exams: {},
}

export const TrainingsContext = createContext({
  data: initialData,
  setData: () => { }
})

const TrainingsContextProvider = props => {

  const [trainingLabels, setTrainingLabels] = useState([])
  const [activeTrainings, setActiveTrainings] = useState([])
  const [exams, setExams] = useState({})
  const [trainingSurveys, setTrainingSurveys] = useState([])
  const [session, setSession] = useState(false)
  const [labeslFetched, setLabelsFetched] = useState(false)

  useEffect(() => {
    hasSession().then(res => {
      if (res == 200) {
        setSession(true)
      }
    })
  }, [])

  useEffect(() => {
    getLabels().then(res => {
      setTrainingLabels(res)
      setLabelsFetched(true)
    })
  }, [])

  useEffect(() => {
    if (trainingLabels?.length > 0) {
      getTrainingAvailable().then(res => {
        const active = res.active.map(training => {
          return {
            title: getTitleFromType(trainingLabels, training),
            type: training
          }
        })
        setActiveTrainings(active)
      })
    }
  }, [labeslFetched])

  useEffect(() => {
    if (session) {
      getPreviousExams().then(res => {
        // sort exams alphab.
        res.sort((a, b) => {
          return a.description.toUpperCase().localeCompare(b.description.toUpperCase())
        })
        const validExamObjects = filterExpiredExams(res)
        const expiredExamObjects = res.filter(exam => !validExamObjects.includes(exam))
        setExams({ validExams: validExamObjects, expiredExams: expiredExamObjects })
      })

      if (document.getElementById("valid-previous-trainings")) {
        document.getElementById("valid-previous-trainings").addEventListener("newKey", () => {
          getPreviousExams().then(res => {
            res.sort((a, b) => {
              return a.description.toUpperCase().localeCompare(b.description.toUpperCase())
            })
            const validExamObjects = filterExpiredExams(res)
            const expiredExamObjects = res.filter(exam => !validExamObjects.includes(exam))
            setExams({ validExams: validExamObjects, expiredExams: expiredExamObjects })
          })
        })
      }
    }
  }, [session])

  const trainingValues = useMemo(
    () => ({ data: { trainingLabels, activeTrainings, exams }, setActiveTrainings, setTrainingLabels, setExams }),
    [trainingLabels, activeTrainings, exams]
  )


  return (
    <TrainingsContext.Provider value={trainingValues}>
      {props.children}
    </TrainingsContext.Provider>
  )
}

export default TrainingsContextProvider
