import React from 'react';
import upBtn from '../../images/upButton.svg';

/** @class styled component for scroll up button */
const ScrollUpComponent = () =>  {
    return (
        <div className="scroll-up-btn">
            <img src={upBtn} className="up-img" alt="up" />
            <div className="circle" />
        </div>
    );
};

export default ScrollUpComponent;