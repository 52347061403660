import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import Statistics from "./Statistics";
import { getAverageResults } from "../../../utils/api";
import ReactLoading from "react-loading";

const ResultsOverview = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const { averageResults } = props

  useEffect(() => {
    setLoaded(true)
  }, []);

  const getRange = () => {
    return (
      Math.min(...averageResults).toString() +
      " bis " +
      Math.max(...averageResults).toString()
    );
  };

  const getAverage = () => {
    let sum = 0;
    for (let i = 0; i < averageResults.length; i++) {
      sum += parseInt(averageResults[i], 10);
    }

    return (sum / averageResults.length).toFixed(2);
  };

  useEffect(() => {
    new Chart("average", {
      type: "bar",
      data: {
        labels: averageResults,
        datasets: [
          {
            label: "Erreichte Punkte",
            data: averageResults,
            backgroundColor: props.mainColor,
            borderColor: props.secondaryColor,
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "white",
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    });
  }, [averageResults]);

  const loadedComponent = !loaded ? (
    <div className={"loading-results"}>
      <ReactLoading
        type={"bubbles"}
        color={"#61bdaa"}
        height={300}
        width={100}
      />
    </div>
  ) : null;

  return (
    <div className={"chartbox col-md-12"}>
      {loadedComponent}
      <h5 className={"text-center"}>Übersicht</h5>
      <div className={"stats-container"}>
        <Statistics title="Durchschnittlich" result={getAverage()} />
        <Statistics title="Bereich" result={getRange()} />
      </div>
      <div>
        <canvas id={"average"} width="200" height="200" />
      </div>
    </div>
  );
};

export default ResultsOverview;
